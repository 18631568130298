<style type="text/css">
  .apexcharts-bar-area {
    margin-top: 30px;
  }

  .exceed-data {
    inset: 10px;
    left: 15px;
    padding: 5px;
    border: 10px solid transparent;
    width: calc(100% - 25px);
    height: calc(100% - 20px);
    animation-name: mymove;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    position: absolute;
  }

  @keyframes mymove {
    from { background: rgb(229 229 229); }
    to { background: rgb(249 249 249); }
  }
</style>

<template>
  <div class="border-2 p-2 h-full w-full bg-white dark:bg-chart-dark dark:border-neutral-600" v-view.once="onceHandler">
    <VueApexCharts ref="chart"
                   v-if="visible && !hideGraphic"
                   width="100%"
                   height="100%"
                   :options="chartOptions"
                   :series="series"/>
    <chart-menu v-if="readOnly === false" :chart="chart"/>
    <div v-if="!exceedData && !visible" class="bg-neutral-200 dark:bg-neutral-800 animate-pulse h-full w-full"></div>
    <div v-if="exceedData && !isLoading" class="bg-neutral-200 dark:bg-neutral-800 exceed-data"></div>
    <div v-if="!data.length && !exceedData || hideGraphic" class="absolute top-1/2 left-1/2 -translate-x-1/2 text-lg font-bold">
      {{ $t('no-data') }}
    </div>
    <LimitModal :seriesLength="seriesLength"
                :currentCountOfCategories="currentCountOfCategories"
                :currentCountOfLabels="currentCountOfLabels"
                :maxCategories="MAX_CATEGORIES"
                :maxLabels="MAX_CATEGORIES_LABELS"/>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { round, uniqBy } from 'lodash'
import utilsMixin from '@/mixins/utils'
import ChartMenu from '@/components/chartsFSP/ChartMenu.vue'
import LimitModal from '@/components/chartsFSP/LimitModal.vue'
import ApiClient from '@/assets/js/ApiClient.js'
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'

function calculateMean (array) {
	const sum = array.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
	return sum / array.length
}

function calculateMedian (array) {
	const sortedArray = array.slice().sort((a, b) => a - b)
	const middle = Math.floor(sortedArray.length / 2)

	if (sortedArray.length % 2 === 0) {
		return (sortedArray[middle - 1] + sortedArray[middle]) / 2
	} else {
		return sortedArray[middle]
	}
}

function getDivergentColorsFunc (nCategories, colorVector) {
	const indexesColorVector = Array.from({ length: nCategories }, (_, i) => Math.round(i * (colorVector.length - 1) / (nCategories - 1)))
	const filteredColorVector = indexesColorVector.map(index => colorVector[index])
	return filteredColorVector
}

function mergeArraysOverwriting (array1, array2) {
	const mergedArray = [...array2]
	for (let i = 0; i < array1.length; i++) {
		mergedArray[i] = array1[i]
	}
	return mergedArray
}

export default {
	mixins: [utilsMixin],
	name: 'Chart',
	components: {
		VueApexCharts,
		LimitModal,
		ChartMenu
	},
	props: {
		chart: {
			type: Object,
			required: true
		},
		language: {
			type: String,
			default: 'de'
		},
		annotations: {
			type: Object,
			required: false,
			default: () => {}
		},
		isEditedChart: {
			type: Boolean,
			default: false
		},
		readOnly: {
			type: Boolean,
			default: false
		},
		stacked: {
			type: Boolean,
			default: false
		},
		stackedFull: {
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			width: 0,
			totals: {},
			visible: false,
			seriesData: [],
			seriesLength: [],
			isDarkMode: false,
			stackType: undefined,
			showCategoryLimitModal: false,
			hideGraphic: false,
			MAX_CATEGORIES: 100,
			MAX_CATEGORIES_LABELS: 100,
			currentCountOfCategories: 0,
			currentCountOfLabels: 0,
			updateOptionsFlag: false,
			updateCategories: false,
			viewDefault: [],
			isLoading: false,
			keysToKeep: ['data', 'groups', 'slices', 'splits'],
		}
	},
	computed: {
		...mapGetters({
			exceedData: 'results/evaluationStore/exceedData',
			filteredDataForChartId: 'results/evaluationStore/filteredDataForChartId',
			seriesDefault: 'results/evaluationStore/seriesDefault',
			currentEvaluation: 'results/evaluations/detailItem',
			editValues: 'editValues'
		}),
		data () {
			let resultData = this.$globalData.results.evaluationStore.resultData
			return this.filteredDataForChartId(this.chart.i, this.chart.filters, resultData)
		},
		isSpecialMode () {
			return ['Count', 'Sum', 'Mean', 'Median', 'Unique'].includes(this.chart.addition)
		},
		viewSetting () {
			return this.chart.viewSetting
		},
		viewSettings () {
			return this.chart.viewSettings
		},
		restrictToFspOnly () {
			const current = this.currentEvaluation.measures
			return current.includes(65) || current.includes(66) || current.includes(101)
		},
		chartType () {
			return this.chart.type
		},
		addition () {
			return this.chart.addition
		},
		series () {
			return this.seriesData
		},
		splitSeries () {
			return this.chart.splits
		},
		sliceSeries () {
			return this.chart.slices
		},
		selectedSeries () {
			return this.chart.data
		},
		computeVariable () {
			return this.selectedSeriesLength && this.chart.data[0] ? this.chart.data[0].key : null
		},
		realSelectedSeries () {
			return this.selectedSeriesLength && this.chart.groups[0] ? this.chart.groups?.slice(0, 1) : this.selectedSeries
		},
		selectedSeriesLength () {
			return this.selectedSeries[0]?.auto_grouping !== true
		},
		showSingleBar () {
			return this.selectedSeriesLength && !this.chart.groups[0]
		},
		groupSeries () {
			return this.selectedSeriesLength ? this.chart.groups?.slice(1) : this.chart.groups
		},
		isGroup () {
			return this.groupSeries.length >= 1
		},
		isMultiGroup () {
			return this.groupSeries.length >= 2
		},
		isMultiSplit () {
			return this.splitSeries.length >= 2
		},
		isSplit () {
			return this.splitSeries.length >= 1
		},
		namedArray () {
			return !this.viewSetting?.length ? [] : ['', ...this.viewSetting.map(
				item => item[0]._title.name_chart)]
		},
		title () {
			if (!this.isEditedChart) {
				return this.translate(this.chart, 'title', this.language)
			} else {
				return this.translate(this.chart, 'title', this.chart.languageTabTitle ? this.chart.languageTabTitle : this.language)
			}
		},
		subtitle () {
			if (!this.isEditedChart) {
				return this.translate(this.chart, 'subtitle', this.language)
			} else {
				return this.translate(this.chart, 'subtitle', this.chart.languageTabSubtitle ? this.chart.languageTabSubtitle : this.language)
			}
		},
		horizontal () {
			return this.viewSettings?.includes('horizontal')
		},
		showDatalabels () {
			return this.viewSettings?.includes('datalabels')
		},
		getTitle () {
			return this.splitTitle(this.title || '', this.getWidth, { fontSize: '20px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
		},
		getSubTitle () {
			return this.splitTitle(this.subtitle || '', this.getWidth, { fontSize: '14px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
		},
		colorSheme () {
			return this.isDarkMode ? 'dark' : 'light'
		},
		chartOptions () {
			const suffix = !this.isSpecialMode || this.chart.type === 'stacked-full' ? '%' : ''
			let options = {
				title: {
					text: this.getTitle,
					align: 'center',
					offsetY: this.readOnly ? 0 : 35,
					margin: 35,
					style: {
						fontSize: '20px',
						fontWeight: 'normal',
						fontFamily: 'Nunito, sans-serif',
						color: this.colorSheme === 'dark' ? '#3a92cf' : '#333333'
					}
				},
				subtitle: {
					text: this.getSubTitle,
					align: 'center',
					margin: this.getSubTitle ? 10 : 0,
					offsetX: 0,
					offsetY: this.getTitle.length * 25 + (this.readOnly ? 25 : 55),
					style: {
						fontSize: '14px',
						fontWeight: 'normal',
						fontFamily: 'Nunito, sans-serif',
						color: this.colorSheme === 'dark' ? '#3a92cf' : '#777777'
					}
				},
				theme: {
					mode: this.colorSheme
				},
				fill: {
					colors: [
						({ seriesIndex}) => {
							return this.colorPalette[seriesIndex]
						}
					]
				},
				legend: {
					markers: {
						fillColors: this.colorPalette
					}
				},
				chart: {
					id: 'basic-bar',
					type: 'bar',
					stacked: this.getStacked || this.stackedFull,
					stackType: this.stackedFull ? '100%' : undefined,
					fontFamily: 'Nunito, sans-serif',
					margin: {
						bottom: 200 // Set your desired excess margin here
					},
					toolbar: {
						show: false,
						tools: {},
						offsetX: -35
					},
					events: {
						mounted: (chart) => {
							if (this.$refs.chart === undefined) return
							this.width = this.$refs.chart.$el.clientWidth
							chart.windowResizeHandler()
						},
						updated: () => {
							if (this.$refs.chart === undefined) return
							this.width = this.$refs.chart.$el.clientWidths
							let combinedArray = this.groupingsArray
							if (this.namedArray.length) {
								combinedArray = [...combinedArray, this.namedArray]
							}

							if (combinedArray.length > 0) {
								this.setBenchmarksCategory(combinedArray, this.$refs.chart.$el)
								if (this.updateOptionsFlag) {
									// Reset the flag to avoid infinite loop
									this.updateOptionsFlag = false
									return
								}

								const newOptions = {
									xaxis: {
										labels: {
											minHeight: combinedArray.length * 30 + 40
										}
									}
								}
								const chart = this.$refs.chart.chart
								chart.updateOptions(newOptions)
								this.updateOptionsFlag = true
							}
						}
					}
				},
				plotOptions: {
					bar: {
						horizontal: this.horizontal,
						columnWidth: '60%',
						dataLabels: {
							position: 'top'
						}
					}
				},
				xaxis: {
					categories: this.categories,
					labels: {
						trim: true,
						rotate: 0
					},
					axisBorder: {
						show: true
					},
					axisTicks: {
						show: true
					}
				},
				yaxis: {
					axisBorder: {
						show: true
					},
					axisTicks: {
						show: true
					},
					labels: {
						trim: true
					}
				},
				tooltip: {
					y: {
						formatter: (val) => {
							return val
						}
					}
				},
				dataLabels: {
					offsetY: !this.getStacked && !this.stackedFull ? -20 : 0,
					enabled: this.showDatalabels,
					formatter: (val) => {
						return isNaN(val) ? '' : val.toFixed(0) + suffix
					},
					style: {
						fontSize: '12px',
						colors: [this.colorSheme === 'dark' ? '#ffffff' : '#304758']
					}
				},
				annotations: this.annotations
			}
			if (!this.horizontal) {
				options.yaxis.labels = {
					show: true,
					formatter: function (val) {
						return val + suffix
					}
				}
				options.xaxis.labels = {
					show: true,
					trim: true,
					rotate: 0,
					hideOverlappingLabels: false,
					formatter: function (val) {
						return val
					}
				}
				options.grid = {
					xaxis: {
						lines: {
							show: false
						}
					},
					yaxis: {
						lines: {
							show: this.chartType === 'bar'
						}
					}
				}
				options.dataLabels.offsetX = 0
			} else {
				options.grid = {
					xaxis: {
						lines: {
							show: true
						}
					},
					yaxis: {
						lines: {
							show: false
						}
					}
				}
				options.yaxis.labels = {
					show: true,
					formatter: function (val) {
						return val
					}
				}
				options.xaxis.labels = {
					show: true,
					formatter: function (val) {
						return val + suffix
					}
				}
				options.dataLabels.offsetX = 35
				options.dataLabels.offsetY = 0
			}
			return options
		},
		getWidth () {
			return this.width
		},
		benchmarkPalette () {
			return ['#d6c9f4', '#b59ae7', '#9277d6', '#775DD0', '#644bb1', '#523c93', '#403073']
		},
		colorPalette () {
			// old config first two colors: ['#559861', '#74B86B']
			const nCategories = this.seriesData.length
			const colorArrayANQ = ['#1d5a84', '#9ab5c2', '#6bc69b', '#1d5a84', '#477899', '#7097ad', '#9ab5c2', '#8abbb5', '#7bc0a8', '#6bc69b']
			const colorArrayFSP = ['#659086', '#B2F881', '#93D876', '#B2F881', '#B4F199', '#B6EAB1', '#B9E4C9', '#BBDDE1', '#BED7F9', '#C9C1D8']
			const colorVector = this.restrictToFspOnly ? colorArrayFSP : colorArrayANQ
			const resultColors = nCategories >= 3 ? mergeArraysOverwriting(getDivergentColorsFunc(nCategories, colorVector), colorVector) : colorVector
			return resultColors
		},
		categories () {
			let tmp = []
			let split = 20

			if (this.type && this.type === 'multi_number') {
				split = 60
				tmp = this.realSelectedSeries.map(serie => this.translate(serie, 'label', this.language))
				tmp = tmp.flat(2).map(t => [t])
			} else {
				let series = this.realSelectedSeries.filter(s => s.data)
				this.realSelectedSeries.filter(s => s.data === undefined).forEach(s => {
					series = [...series, ...s.keys]
				})
				tmp = series.reduce((acc, serie) => {
					serie.data.forEach((v, index) => {
						if (!acc[index]) {
							acc[index] = []
						}
						if (acc[index].indexOf(this.translate(v, 'label', this.language) || v.key) === -1) {
							acc[index].push(this.translate(v, 'label', this.language) != null ? this.translate(v, 'label', this.language) : v.key)
						}
					})

					return acc
				}, [])
			}
			let result = []
			tmp.forEach(str => {
				const join = str.join('\n - ')
				result.push(this.splitter([join], split))
			})
			if (this.groupSeries.length > 0) {
				result = this.duplicate2DArray(result, this.groupingsAmount)
			} if (this.updateCategories === true) {
				result = this.duplicate2DArray(result, this.viewSetting.length + 1)
			}

			if (this.showSingleBar) {
				return ['']
			} else {
				return result
			}
		},
		getStacked () {
			return this.stacked
		},
		getStackType () {
			return this.stackType
		},
		groupingsArray () {
			const baseArray = []
			const lengthBenchmark = this.namedArray.length || 1
			for (const i in this.groupSeries) {
				let base = this.groupSeries[i].data.map(_ => _.label)
				const ancestor = this.groupSeries[Number(i) + 1] || []
				const lengthGrouping = (ancestor.data || []).length || 1
				const lengthTogether = lengthGrouping * lengthBenchmark
				if (lengthTogether > 1) {
					base = this.duplicate2DArray(base, lengthTogether)
				}
				baseArray.push(base)
			}
			return baseArray
		},
		groupingsAmount () {
			const base = this.groupSeries.map(_ => _.data.length)
			const product = base.reduce((accumulator, currentValue) => {
				return accumulator * currentValue
			}, 1)
			return product
		}
	},
	methods: {
		convertToIntsIfNumeric (stringArray) {
			// Check if all entries can be converted to a number
			const allNumeric = stringArray.every(item => !isNaN(item))
			// If all are numeric, convert to integers, otherwise return the original array
			return allNumeric ? stringArray.map(Number) : stringArray
		},
		filterValues (data, v) {
			return data.filter(d => v.measure.id === d.measure && v.value != null && d.attributes[v.key] != null && d.attributes[v.key].toString() === v.value.toString())
		},
		complementer (desiredLength, originalArray) {
			return Array.from({ length: desiredLength }, (_, index) => originalArray[index] || 0)
		},
		duplicate2DArray (array2D, multiplier) {
			const duplicatedArray = []

			for (let i = 0; i < multiplier; i++) {
				duplicatedArray.push(...array2D)
			}

			return duplicatedArray
		},
		specialModes (items) {
			const baseArray = []
			const addition = this.chart.addition
			const limiter = items.map(_ => _.count.length)
			for (let i = 0; i < Math.max(...limiter); i++) {
				const prepare = items.map(item => item.count[i] || 0)
				baseArray.push(prepare.reduce((total, current) => total + current, 0))
			}

			for (const i of items) {
				if (addition === 'Count') {
					i.data = i.count
				} else if (addition === 'Sum') {
					i.data = i.sum
				} else if (addition === 'Mean') {
					i.data = i.mean
				} else if (addition === 'Median') {
					i.data = i.median
				} else if (addition === 'Unique') {
					i.data = i.unique
				} else {
					if (items.length <= 1) {
						i.data = i.total
					} else {
						i.data = i.count.map((item, index) => {
							const temp = item / baseArray[index]
							return round(temp * 100, 1)
						})
					}
				}
			}
		},
		setupTrigger (result, temporary) {
			temporary.forEach((r, rIx) => {
				if (!result[rIx]) {
					result[rIx] = {}
				}
				if (!result[rIx].count) {
					result[rIx].count = []
				}
				if (!result[rIx].data) {
					result[rIx].data = []
				}
				if (!result[rIx].label) {
					result[rIx].label = []
				}
				if (!result[rIx].mean) {
					result[rIx].mean = []
				}
				if (!result[rIx].median) {
					result[rIx].median = []
				}
				if (!result[rIx].sum) {
					result[rIx].sum = []
				}
				if (!result[rIx].total) {
					result[rIx].total = []
				}
				if (!result[rIx].unique) {
					result[rIx].unique = []
				}

				const limit = Math.max(...temporary.map(_ => _.data.length))
				result[rIx].count.push(...this.complementer(limit, r.count))
				result[rIx].data.push(...this.complementer(limit, r.data))
				result[rIx].label.push(...this.complementer(limit, r.label))
				result[rIx].mean.push(...this.complementer(limit, r.mean))
				result[rIx].median.push(...this.complementer(limit, r.median))
				result[rIx].sum.push(...this.complementer(limit, r.sum))
				result[rIx].total.push(...this.complementer(limit, r.total))
				result[rIx].unique.push(...this.complementer(limit, r.unique))
				result[rIx].name = r.name
			})
			return result
		},
		async setupData () {
			let result = []
			this.totals = {}
			this.updateCategories = false
			if (!this.visible || !this.data?.length && !this?.exceedData) {
				this.seriesData = []
				return
			}

			if (this.exceedData === true) {
				result = cloneDeep(this.viewDefault)
			}

			else {
				if (this.groupSeries.length >= 1) {
					const arrLength = this.groupSeries.length
					const primary = this.groupSeries[arrLength - 1]
					const secondary = this.groupSeries[arrLength - 2]

					primary.data.forEach(vOld => {
						const tertiary = this.isMultiGroup ? secondary.data : [vOld]
						tertiary.forEach(vNew => {
							let filtered = []
							let temporary = []

							if (vOld.customFunc) {
								filtered = this.data.filter(d => vNew.customFunc(d.attributes[vNew.key]))
							} else {
								if (this.isGroup) {
									filtered = this.filterValues(this.data, vNew)
								}
								if (this.isMultiGroup) {
									filtered = this.filterValues(filtered, vOld)
								}
							}
							if (filtered.length > 0) {
								if (this.type && this.type === 'multi_number') {
									temporary = this.buildSeriesMultiNumber(temporary, filtered)
								} else {
									if (this.isSplit) {
										temporary = this.buildSplitSeries(temporary, filtered)
									} else {
										temporary = this.buildSeries(temporary, filtered)
									}
								}
								this.specialModes(temporary)
								result = this.setupTrigger(result, temporary)
							}
						})
					})
				}

				if (this.groupSeries.length === 0) {
					if (this.type && this.type === 'multi_number') {
						result = this.buildSeriesMultiNumber(result, this.data)
					} else {
						if (this.isSplit) {
							result = this.buildSplitSeries(result, this.data)
						} else {
							result = this.buildSeries(result, this.data)
						}
					}
					this.specialModes(result)
				}
			}

			if (result?.length) {
				this.hideGraphic = false
				const limit = result.map(_ => _.data.length)
				const maxLimit = Math.max(...limit)
				const seriesLength = result.map(_ => _.data).flat()
				this.checkLimitOfCategoriesInGraphic(maxLimit, result.length)
				this.currentCountOfCategories = maxLimit
				this.currentCountOfLabels = result.length
				this.seriesLength = seriesLength

				if (this.viewSetting?.length) {
					if (maxLimit >= 0) {
						for (const i in result) {
							const master = result[i].data
							for (const j of this.viewSetting) {
								const slave = j[i].data
								master.push(...slave)
							}
						}
						this.updateCategories = true
					} else {
						for (const j in result) {
							const temporary = result[j].data
							result[j].data = temporary.map(
								(item, index) => {
									const goals = []
									for (const i of this.viewSetting) {
										const ind = this.viewSetting.indexOf(i)
										const color = this.benchmarkPalette[ind]
										const benchmark = i[j].data
										const name = i[j]._title
										goals.push({
											strokeHeight: 6,
											strokeColor: color,
											value: benchmark[index],
											name: name.name_chart
										})
									}
									return {
										x: '',
										y: item,
										goals: goals
									}
								})
						}
					}
				}
				this.seriesData = result
			}
		},
		onceHandler () {
			this.visible = true
		},
		checkLimitOfCategoriesInGraphic (currentCountOfCategories, currentCountOfLabels) {
			if (currentCountOfCategories > this.MAX_CATEGORIES ||
      currentCountOfLabels > this.MAX_CATEGORIES_LABELS) {
				this.hideGraphic = true
			}
		},
		buildSeriesMultiNumber (result) {
			this.realSelectedSeries.forEach((serie, serieIx) => {
				this.totals[serieIx] = {}
				serie.options.forEach((option, ix) => {
					let obj = {}
					obj.name = option.label
					obj.data = []
					serie.data.forEach((v) => {
						// remove undefined
						const tmp = this.data.filter(value => (value.attributes[v.key][option.name_new] !== undefined)).map(value => parseFloat(value.attributes[v.key][option.name_new]))
						const value = tmp.reduce((prev, curr) => {
							return prev + curr
						}, 0) / tmp.length
						this.totals[serieIx][ix] = tmp.length
						obj.data.push(round(value, 2))
					})
					result.push(obj)
				})
			})
			return result
		},
		calculateResults (result, serie, data) {
			const compute = this.computeVariable
			const singleBar = this.showSingleBar
			let singleBarArray = null
			result.label = []
			result.count = []
			result.sum = []
			result.mean = []
			result.median = []
			result.unique = []

			if (singleBar) {
				singleBarArray = [{
					key: serie.key,
					label: serie.label,
					measure: {
						id: serie.measure.id
					}
				}]
			}

			(singleBarArray || serie.data).forEach(v => {
				const baseArray = []
				data.forEach(value => {
					if (singleBar) {
						if (value.measure === v.measure.id) {
							if (value.attributes.record_id === 1 || serie.blow_in_graph !== true) {
								if (value.attributes[v.key] != null) {
									baseArray.push(value.attributes[v.key])
								}
							}
						}
					} else if (serie.customFunc) {
						if (v.measure.id === value.measure && serie.customFunc(value.attributes[v.key])) {
							if (value.attributes.record_id === 1 || serie.blow_in_graph !== true) {
								if (value.attributes[compute || v.key] != null) {
									baseArray.push(value.attributes[compute || v.key])
								}
							}
						}
					} else {
						if (v.measure.id === value.measure && v.value != null && value.attributes[v.key] != null && String(value.attributes[v.key]) === String(v.value)) {
							if (value.attributes.record_id === 1 || serie.blow_in_graph !== true) {
								if (value.attributes[compute || v.key] != null) {
									baseArray.push(value.attributes[compute || v.key])
								}
							}
						}
					}
				})
				result.label.push(v.value)
				result.count.push(baseArray.length)
				result.unique.push([...new Set(baseArray)].length)

				const convertedBaseArray = this.convertToIntsIfNumeric(baseArray)
				if (convertedBaseArray.every(item => typeof item === 'number')) {
					result.sum.push(round(convertedBaseArray.reduce((total, current) => total + current, 0), 2))
					result.mean.push(round(calculateMean(convertedBaseArray), 2))
					result.median.push(round(calculateMedian(convertedBaseArray), 2))
				}
			})
			const total = result.count.reduce((total, current) => total + current, 0)
			result.total = result.count.map(value => round((value / total * 100), 1)) || []
			return result
		},
		buildSeries (result, data) {
			const hasMultipleMeasures = uniqBy(this.realSelectedSeries, v => v.measure ? v.measure.id : 0).length > 1
			this.realSelectedSeries.forEach(serie => {
				let obj = {}
				if (serie.data) {
					obj = this.calculateResults(obj, serie, data)
				} else {
					serie.keys.forEach(dim => {
						obj = this.calculateResults(obj, dim, data)
					})
				}

				if (hasMultipleMeasures && serie.measure) {
					obj.name = `${serie.measure.name}: ${serie.label || serie.title}`
				} else {
					const getCurrentLabel = (optionData) => {
						let currentTitle = ''
						if (this.language === 'it' && (optionData.label_it || optionData.title_it)) {
							currentTitle = optionData.label_it ? optionData.label_it : optionData.title_it
						} else if (this.language === 'fr' && (optionData.label_fr || optionData.title_fr)) {
							currentTitle = optionData.label_fr ? optionData.label_fr : optionData.title_fr
						} else {
							currentTitle = optionData.label ? optionData.label : optionData.title
						}
						return currentTitle
					}
					obj.name = getCurrentLabel(serie)
				}

				result.push(obj)
			})
			return result
		},
		buildSplitSeries (result, data) {
			const hasMultipleMeasures = uniqBy(this.realSelectedSeries, v => v.measure ? v.measure.id : 0).length > 1
			const serie = this.realSelectedSeries[0]
			const arrLength = this.splitSeries.length
			const primary = this.splitSeries[arrLength - 1]
			const secondary = this.splitSeries[arrLength - 2]

			primary.data.forEach((vOld, i) => {
				const tertiary = this.isMultiSplit ? secondary.data : [vOld]
				tertiary.forEach((vNew, j) => {
					let filtered = []
					let obj = {}

					if (this.isSplit) {
						filtered = this.filterValues(data, vNew)
					}
					if (this.isMultiSplit) {
						filtered = this.filterValues(filtered, vOld)
					}

					if (serie.data) {
						obj = this.calculateResults(obj, serie, filtered)
					} else {
						serie.keys.forEach(dim => {
							obj = this.calculateResults(obj, dim, filtered)
						})
					}

					if (hasMultipleMeasures) {
						obj.name = `${vNew.measure.name}: ${vNew.label}`
					} else {
						if (this.isMultiSplit) {
							const child = this.splitSeries[0].data[j]
							const parent = this.splitSeries[1].data[i]
							obj.name = this.translate(parent, 'label', this.language) + ' ' + this.translate(child, 'label', this.language)
						} else {
							obj.name = this.translate(vNew, 'label', this.language)
						}
					}

					result.push(obj)
				})
			})
			return result
		},
		filterKeys (originalObj, keysToKeep) {
			return keysToKeep.reduce((obj, key) => {
				if (key in originalObj) {
					obj[key] = originalObj[key]
				}
				return obj
			}, {})
		},
		addNameToObject (fetch) {
			if (this.isSplit) {
				const arrLength = this.splitSeries.length
				const primary = this.splitSeries[arrLength - 1]
				const secondary = this.splitSeries[arrLength - 2]

				primary.data.forEach((vOld, i) => {
					const tertiary = this.isMultiSplit ? secondary.data : [vOld]
					tertiary.forEach((vNew, j) => {
						if (this.isMultiSplit) {
							console.log(tertiary.length)
							let indexer = tertiary.length * i + j
							const child = this.splitSeries[0].data[j]
							const parent = this.splitSeries[1].data[i]
							fetch[indexer]['name'] = this.translate(parent, 'label', this.language) + ' ' + this.translate(child, 'label', this.language)
						} else {
							fetch[i]['name'] = this.translate(vNew, 'label', this.language)
						}
					})
				})
			}
			else {
				this.realSelectedSeries.forEach(serie => {
					if (this.language === 'it') {
						fetch[0]['name'] = serie.label_it || serie.title_it
					} else if (this.language === 'fr') {
						fetch[0]['name'] = serie.label_fr || serie.title_fr
					} else {
						fetch[0]['name'] = serie.label || serie.title
					}
				})
			}
		},
		async fetchexceedData () {
			if (this.exceedData === true) {
				const filteredUrl = `results/compute-${this.chart.type}/`
				const filteredObj = this.filterKeys(this.chart, this.keysToKeep)
				const compute = !this.chart.addition || this.chart.addition === 'Bitte wählen'
				filteredObj.addition = !compute ? this.chart.addition : 'Percentage'
				filteredObj.measure = this.chart?.measures[0] || this.chart?.data[0]?.measure.id
				filteredObj.days_back = this.currentEvaluation.days_back
				// add global filters
				filteredObj.filters = this.editValues.filters
				const fetch = await ApiClient.post(filteredUrl, filteredObj)
				this.addNameToObject(fetch.data[0])
				this.viewDefault = fetch.data[0]
				this.isLoading = true
			}
		}
	},
	async mounted () {
		this.isDarkMode = document.documentElement.classList.contains('dark')
	},
	watch: {
		addition: {
			async handler () {
				this.viewDefault = []
				// eslint-disable-next-line
        		this.chart.viewSetting = []
			}
		},
		selectedSeries: {
			async handler () {
				this.viewDefault = []
				// eslint-disable-next-line
       			this.chart.viewSetting = []
			}
		},
		splitSeries: {
			async handler () {
				this.viewDefault = []
				// eslint-disable-next-line
        		this.chart.viewSetting = []
			}
		},
		groupSeries: {
			async handler () {
				this.viewDefault = []
				// eslint-disable-next-line
        		this.chart.viewSetting = []
			}
		},
		viewSetting: {
			async handler () {
				this.isLoading = false
				if (!this.viewDefault?.length) {
					await this.fetchexceedData() }
				else { this.isLoading = true }
				this.setupData()
			}
		},
		visible: {
			async handler () {
				await this.fetchexceedData()
				this.setupData()
			}
		}
	}
}
</script>
