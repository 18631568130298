<style type="text/css">
  .apexcharts-bar-area {
    margin-top: 30px;
  }

  .exceed-data {
    inset: 10px;
    left: 15px;
    padding: 5px;
    border: 10px solid transparent;
    width: calc(100% - 25px);
    height: calc(100% - 20px);
    animation-name: mymove;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    position: absolute;
  }

  @keyframes mymove {
    from { background: rgb(229 229 229); }
    to { background: rgb(249 249 249); }
  }
</style>

<template>
  <div class="border-2 p-2 h-full w-full bg-white dark:bg-chart-dark dark:border-neutral-600" v-view.once="onceHandler">
    <VueApexCharts
      v-if="(visible || print) && !hideGraphic"
      ref="chart"
      width="100%"
      height="100%"
      :options="chartOptions"
      :series="series"
    />
    <chart-menu v-if="visible && !readOnly" :chart="chart" />
    <div v-if="!exceedData && !visible" class="bg-neutral-200 animate-pulse h-full w-full dark:bg-neutral-800"></div>
    <div v-if="exceedData && !isLoading" class="bg-neutral-200 dark:bg-neutral-800 exceed-data"></div>
    <div v-if="hideGraphic" class="absolute top-1/2 left-1/2 -translate-x-1/2 text-lg font-bold">
      {{ $t('no-data') }}
    </div>
    <LimitModal :seriesLength="seriesLength" :currentCountOfLabels="currentCountOfLabels" :maxLabels="MAX_CATEGORIES_LABELS"/>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import utilsMixin from '@/mixins/utils'
import ApiClient from '@/assets/js/ApiClient.js'
import ChartMenu from '@/components/chartsFSP/ChartMenu.vue'
import LimitModal from '@/components/chartsFSP/LimitModal.vue'
import { mean, round, uniqBy, cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'

export default {
	mixins: [utilsMixin],
	data () {
		return {
			height: 0,
			width: 0,
			MAX_CATEGORIES_LABELS: 100,
			currentCountOfLabels: 0,
			hideGraphic: false,
			visible: false,
			seriesLength: null,
			seriesData: [],
			isLoading: false,
			isDarkMode: false,
			keysToKeep: ['data', 'groups', 'slices', 'splits'],
		}
	},
	props: {
		chart: {
			type: Object,
			required: true
		},
		annotations: {
			type: Object,
			required: false,
			default: () => {}
		},
		isEditedChart: {
			type: Boolean,
			default: false
		},
		language: {
			type: String,
			default: 'de'
		},
		print: {
			type: Boolean,
			required: false,
			default: false
		},
		readOnly: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapGetters({
			exceedData: 'results/evaluationStore/exceedData',
			resultBenchmarkForMeasureId: 'results/evaluationStore/resultBenchmarkForMeasureId',
			benchmarkNames: 'results/evaluationStore/benchmarkNames',
			filteredDataForChartId: 'results/evaluationStore/filteredDataForChartId',
			currentEvaluation: 'results/evaluations/detailItem',
			editValues: 'editValues'
		}),
		restrictToFspOnly () {
			const current = this.currentEvaluation.measures
			return current.includes(65) || current.includes(66) || current.includes(101)
		},
		restrictToFspAnq () {
			const current = this.currentEvaluation.measures
			return current.includes(65) || current.includes(66) || current.includes(101) || current.includes(113) || current.includes(61) || current.includes(62) || current.includes(63)
		},
		colorSheme () {
			return this.isDarkMode ? 'dark' : 'light'
		},
		data () {
			let resultData = this.$globalData.results.evaluationStore.resultData
			return this.filteredDataForChartId(this.chart.i, this.chart.filters, resultData)
		},
		title () {
			if (!this.isEditedChart) {
				return this.translate(this.chart, 'title', this.language)
			} else {
				return this.translate(this.chart, 'title', this.chart.languageTabTitle ? this.chart.languageTabTitle : this.language)
			}
		},
		subtitle () {
			if (!this.isEditedChart) {
				return this.translate(this.chart, 'subtitle', this.language)
			} else {
				return this.translate(this.chart, 'subtitle', this.chart.languageTabSubtitle ? this.chart.languageTabSubtitle : this.language)
			}
		},
		viewSetting () {
			return this.chart.viewSetting
		},
		getWidth () {
			return this.width
		},
		getTitle () {
			return this.splitTitle(this.title, this.getWidth, { fontSize: '20px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
		},
		series () {
			return this.seriesData
		},
		splitSeries () {
			return this.chart.splits.length > 0 ? this.chart.splits[0].data : []
		},
		selectedSeries () {
			return this.chart.data
		},
		getSubTitle () {
			return this.splitTitle(this.subtitle, this.getWidth, { fontSize: '14px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
		},
		namedArray () {
			return !this.viewSetting || !this.viewSetting.length ? [] : [['', ...this.viewSetting.map(
				item => item[0]._title.name_chart)]]
		},
		chartOptions () {
			return {
				title: {
					text: this.getTitle,
					align: 'center',
					offsetY: this.readOnly ? 0 : 35,
					margin: 35,
					style: {
						fontSize: '20px',
						fontWeight: 'normal',
						fontFamily: 'Nunito, sans-serif',
						color: this.colorSheme === 'dark' ? '#3a92cf' : '#333333'
					}
				},
				subtitle: {
					text: this.getSubTitle,
					align: 'center',
					margin: this.getSubTitle ? 5 : 0,
					offsetX: 0,
					offsetY: this.getTitle.length * 25 + (this.readOnly ? 25 : 55),
					style: {
						fontSize: '14px',
						fontWeight: 'normal',
						fontFamily: 'Nunito, sans-serif',
						color: this.colorSheme === 'dark' ? '#3a92cf' : '#777777'
					}
				},
				chart: {
					type: 'boxPlot',
					fontFamily: 'Nunito, sans-serif',
					toolbar: {
						show: false,
						offsetX: -30
					},
					events: {
						mounted: (chart) => {
							if (this.$refs.chart === undefined) return
							this.height = this.$refs.chart.$el.clientHeight
							this.width = this.$refs.chart.$el.clientWidth
							chart.windowResizeHandler()
						},
						updated: () => {
							if (this.$refs.chart === undefined) return
							this.height = this.$refs.chart.$el.clientHeight
							this.width = this.$refs.chart.$el.clientWidth
							if (this.showErrorBars) {
								this.buildErrorBars()
							}
							const divArray = this.namedArray
							if (divArray.length > 0) {
								this.setBenchmarksCategory(divArray, this.$refs.chart.$el)

								if (this.updateOptionsFlag) {
									// Reset the flag to avoid infinite loop
									this.updateOptionsFlag = false
									return
								}

								const newOptions = {
									xaxis: {
										labels: {
											minHeight: divArray.length * 30 + 40,
											formatter: function (val) {
												return val ? `${val}`.split('-')[0] : ''
											}
										}
									}
								}

								const chart = this.$refs.chart.chart
								chart.updateOptions(newOptions)
								this.updateOptionsFlag = true
							}
						}
					}
				},
				plotOptions: {
					boxPlot: {
						// old config upper: 559861
						// old config lower: 74B86B
						colors: {
							upper: this.restrictToFspOnly ? '#659086' : '#1d5a84',
							lower: this.restrictToFspOnly ? '#B2F881' : '#9ab5c2'
						}
					}
				},
				tooltip: {
					custom: function ({ seriesIndex, dataPointIndex, w }) {
						const baseArray = w.config.series[seriesIndex].data[dataPointIndex].y
						return (
							'<div style="padding:20px 30px;padding-left:10px;font-size:12px;color:black;" class="apexcharts-tooltip-candlestick">' +
      '<div><strong>Maximum</strong>: <span class="value">' +
      baseArray[6] +
      '</span></div>' +
      '<div><strong>Max Whisker</strong>: <span class="value">' +
        baseArray[4] +
      '</span></div>' +
      '<div><strong>Q3</strong>: <span class="value">' +
        baseArray[3] +
      '</span></div>' +
      '<div><strong>Median</strong>: <span class="value">' +
        baseArray[2] +
      '</span></div>' +
      '<div><strong>Mean</strong>: <span class="value">' +
        baseArray[7] +
      '</span></div>' +
      '<div><strong>Q1</strong>: <span class="value">' +
        baseArray[1] +
      '</span></div>' +
      '<div><strong>Min Whisker</strong>: <span class="value">' +
        baseArray[0] +
      '</span></div>' +
      '<div><strong>Minimum</strong>: <span class="value">' +
        baseArray[5] +
      '</span></div>' +
      '<div><strong>Count n</strong>: <span class="value">' +
        baseArray[8] +
      '</span></div>' +
      '</div>'
						)
					}
				},
				xaxis: {
					categories: [], // this.categories,
					labels: {
						maxHeight: 200,
						formatter: function (val) {
							return typeof val === 'number' && val !== null ? val.toFixed(2) : val
						}
					},
					axisBorder: {
						show: true
					},
					axisTicks: {
						show: true
					}
				},
				yaxis: {
					axisBorder: {
						show: true
					},
					axisTicks: {
						show: true
					},
					labels: {
						trim: true,
						formatter: function (val) {
							return typeof val === 'number' && val !== null ? val.toFixed(2) : val
						}
					}
				},
				annotations: this.annotations
			}
		}
	},
	methods: {
		buildErrorBars () {
			const groups = document.querySelectorAll('g[className="apexcharts-bar-goals-groups"]')
			for (let i = 0; i < groups.length; ++i) {
				let group = groups[i]

				const errorBars = group.querySelectorAll('line[stroke="#aaaaaa"]')
				if (errorBars.length === 2) {
					let errorBar1 = errorBars[0]
					let errorBar2 = errorBars[1]
					let line = document.createElement('line')
					const yVal = errorBar1.y1.baseVal.value + ((errorBar1.y2.baseVal.value - errorBar1.y1.baseVal.value) / 2)
					line.id = `${errorBar1.id}_${errorBar2.id}`
					line.setAttribute('stroke', '#aaaaaa')
					line.setAttribute('stroke-width', '2')
					line.setAttribute('x1', errorBar1.x1.baseVal.value)
					line.setAttribute('x2', errorBar2.x1.baseVal.value)
					line.setAttribute('y1', yVal)
					line.setAttribute('y2', yVal)
					line.setAttribute('stroke-dasharray', 0)
					line.setAttribute('stroke-linecap', 'butt')
					line.setAttribute('transform', 'matrix(1,0,0,1,0,0)')
					// shorten the bars
					errorBar1.setAttribute('y1', errorBar1.y1.baseVal.value + 4)
					errorBar1.setAttribute('y2', errorBar1.y2.baseVal.value - 4)
					errorBar2.setAttribute('y1', errorBar2.y1.baseVal.value + 4)
					errorBar2.setAttribute('y2', errorBar2.y2.baseVal.value - 4)

					group.append(line)
					let newVal = group.innerHTML
					group.innerHTML = newVal
				}
			}
		},
		checkLimitOfCategoriesInGraphic (currentCountOfLabels) {
			if (currentCountOfLabels > this.MAX_CATEGORIES_LABELS) {
				this.hideGraphic = true
			}
		},
		setupData () {
			let result = []
			if (!this.visible || !this.data?.length && !this?.exceedData) {
				this.seriesData = []
				return
			}

			if (this.exceedData === true) {
				result = cloneDeep(this.viewDefault).map(item => {
					return {x: item.data[0].x, y: item.data[0].y, name:item.name}})
			}
			else {
				result = this.buildSplitSeries(result, this.data)
			}

			if (result?.length) {
				const data = this.viewSetting
				const firstValue = result[0]?.x
				const secondValue = result[1]?.x
				// Ensure data is not null or undefined
				// computation of benchmarks

				if (data?.length > 0) {
					let i = 0
					for (const value of data) {
						for (const value2 of value) {
							if (value2?.data?.length > 0) {
								const label = value2.data[0]?.x

								if (firstValue && secondValue && value.length < 2) {
									if (secondValue === label) {
										result.push({
											x: `${firstValue}-${i}`,
											y: []
										})
										result.push({
											x: `${label}-${i}`, // Ensure a unique identifier
											y: value2.data[0].y
										})
									} else {
										result.push({
											x: `${label}-${i}`, // Ensure a unique identifier
											y: value2.data[0].y
										})
										result.push({
											x: `${secondValue}-${i}`,
											y: []
										})
									}
								} else {
									result.push({
										x: `${label}-${i}`, // Ensure a unique identifier
										y: value2.data[0].y
									})
								}
								i++
							}
						}
					}
				}

				// Set all elements of `y` to null if there is any NaN or Infinite value in `y`
				const resultCheck = result.map(item => ({
					...item,
					y: item.y.some(val => isNaN(val) || !isFinite(val)) ? item.y.map(() => null) : item.y
				}))
				const allYsAreNull = resultCheck.every(item => item.y.every(val => val === null))
				const resultFinal = allYsAreNull ? result : resultCheck

				// show pop-up window if results exceed threshold (keep fluent UX)
				this.hideGraphic = false
				const showAllArrayValues = resultFinal.map(item => item.y).flat()
				const checkIfAllNumeric = showAllArrayValues.every(item => !isNaN(item))
				this.checkLimitOfCategoriesInGraphic(resultFinal.length)
				this.currentCountOfLabels = resultFinal.length
				this.seriesData = [{ data: resultFinal }]
				this.seriesLength = checkIfAllNumeric ? null : []
			}
		},
		calculateSplitResults (serieKey, splitValue, data, blowInGraph) {
			let output = data.filter(value => {
				let result = false
				if (splitValue.customFunc) {
					result = splitValue.customFunc(value.attributes[splitValue.key])
				} else if (!splitValue.key) {
					if (value.attributes.record_id === 1 || blowInGraph !== true) {
						result = value.attributes[serieKey] != null
					}
				} else {
					if (value.attributes.record_id === 1 || blowInGraph !== true) {
						result = value.attributes[splitValue.key] && String(value.attributes[splitValue.key]) === String(splitValue.value)
					}
				}

				return result
			})
			let final = output.map(val => val.attributes[serieKey])
			return final
		},
		buildSplitSeries (result, data) {
			const hasMultipleMeasures = uniqBy(this.selectedSeries, v => v.measure ? v.measure.id : 0).length > 1
			const checkIfSplitisEmpty = this.splitSeries.length ? this.splitSeries : [{ key: '', label: this.selectedSeries[0]?.label, label_fr: this.selectedSeries[0]?.label_fr, label_it: this.selectedSeries[0]?.label_it, value: this.selectedSeries[0]?.value, measure: this.selectedSeries[0]?.measure }]
			checkIfSplitisEmpty.forEach((splitValue) => {
				this.selectedSeries.forEach((serie) => {
					let obj = {}
					if (hasMultipleMeasures) {
						obj.name = `${splitValue.measure.name}: ${splitValue.label}`
					} else {
						obj.name = this.translate(splitValue, 'label', this.language)
					}
					obj = []

					let tmp = this.calculateSplitResults(serie.key, splitValue, data, serie.blow_in_graph)
					const boxPlotData = Object.values(tmp).sort((a, b) => a - b).filter(value => value != null && !isNaN(value)).map(value => Number(value))
					// Step 2: Calculate the median (Q2)
					const median = round((boxPlotData.length % 2 === 0)
						? (boxPlotData[boxPlotData.length / 2 - 1] + boxPlotData[boxPlotData.length / 2]) / 2
						: boxPlotData[Math.floor(boxPlotData.length / 2)], 2)

					// Step 3: Calculate Q1 (First Quartile)
					const lowerHalf = boxPlotData.slice(0, Math.floor(boxPlotData.length / 2))
					let q1 = round((lowerHalf.length % 2 === 0)
						? (lowerHalf[lowerHalf.length / 2 - 1] + lowerHalf[lowerHalf.length / 2]) / 2
						: lowerHalf[Math.floor(lowerHalf.length / 2)], 2)

					// Step 4: Calculate Q3 (Third Quartile) using Tukey's method
					const upperHalf = boxPlotData.slice(Math.ceil(boxPlotData.length / 2))
					const n = upperHalf.length
					const lowerMidpoint = Math.floor(n / 2)
					const upperMidpoint = Math.floor((n + 1) / 2)
					let q3 = round((n % 2 === 0)
						? (upperHalf[lowerMidpoint - 1] + upperHalf[upperMidpoint]) / 2
						: upperHalf[upperMidpoint], 2)

					const iqr = q3 - q1

					// Step 5: Calculate theoretical Whiskers
					const theoreticalLowerWhisker = round(q1 - 1.5 * iqr, 2)
					const theoreticalUpperWhisker = round(q3 + 1.5 * iqr, 2)

					// Step 6: Adjust Whiskers to the nearest actual data points
					let lowerWhisker = round(boxPlotData.find(value => value >= theoreticalLowerWhisker) || Math.min(...boxPlotData), 2)
					let upperWhisker = round(boxPlotData.reverse().find(value => value <= theoreticalUpperWhisker) || Math.max(...boxPlotData), 2)

					const minimum = round(Math.min(...boxPlotData), 2)
					const maximum = round(Math.max(...boxPlotData), 2)
					const means = round(mean(boxPlotData), 2)
					const length = boxPlotData.length

					// Temporary fixing nan. Consider disabling computation of these statistial values for series with less than 4 instances.
					if (boxPlotData.length < 4) {
						lowerWhisker = minimum
						upperWhisker = maximum
						q1 = minimum
						q3 = maximum
					}

					obj.push({
						x: this.translate(splitValue, 'label', this.language),
						y: [lowerWhisker, q1, median, q3, upperWhisker, minimum, maximum, means, length]
					})
					result = [...result, ...obj]
				})
			})
			return result
		},
		onceHandler () {
			this.visible = true
		},
		filterKeys (originalObj, keysToKeep) {
			return keysToKeep.reduce((obj, key) => {
				if (key in originalObj) {
					obj[key] = originalObj[key]
				}
				return obj
			}, {})
		},
		async fetchexceedData () {
			if (this.exceedData === true) {
				const filteredUrl = `results/compute-${this.chart.type}/`
				const filteredObj = this.filterKeys(this.chart, this.keysToKeep)
				const compute = !this.chart.addition || this.chart.addition === 'Bitte wählen'
				filteredObj.addition = !compute ? this.chart.addition : 'Percentage'
				filteredObj.measure = this.chart?.measures[0] || this.chart?.data[0]?.measure.id
				filteredObj.days_back = this.currentEvaluation.days_back
				// add global filters
				filteredObj.filters = this.editValues.filters
				const fetch = await ApiClient.post(filteredUrl, filteredObj)
				this.viewDefault = fetch.data[0]
				this.isLoading = true
			}
		}
	},
	components: {
		VueApexCharts,
		LimitModal,
		ChartMenu
	},
	mounted () {
		this.isDarkMode = document.documentElement.classList.contains('dark')
	},
	watch: {
		addition: {
			async handler () {
				this.viewDefault = []
				// eslint-disable-next-line
        		this.chart.viewSetting = []
			}
		},
		selectedSeries: {
			async handler () {
				this.viewDefault = []
				// eslint-disable-next-line
        		this.chart.viewSetting = []
			}
		},
		splitSeries: {
			async handler () {
				this.viewDefault = []
				// eslint-disable-next-line
       			 this.chart.viewSetting = []
			}
		},
		viewSetting: {
			async handler () {
				this.isLoading = false
				if (!this.viewDefault?.length) {
					await this.fetchexceedData() }
				else { this.isLoading = true }
				this.setupData()
			}
		},
		visible: {
			async handler () {
				await this.fetchexceedData()
				this.setupData()
			}
		}
	}
}
</script>
