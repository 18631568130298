<style scoped>
 .dropdown {
   max-height: 300px;
 }
</style>
<template>
  <div>
    <div v-if="showMenu" class="top-0 left-0 z-50 fixed w-full h-screen" @click="showMenu = false;activeHelper = true;filterKey=null"></div>
    <div
      class="inline-block relative w-full cursor-pointer"
      :class="{'cursor-not-allowed' : disabled}"
      @click.stop="!disabled ? showMenu = !showMenu : null"
    >
      <div class="border-2 bg-white dark:bg-neutral-900 inline-flex items-center justify-between w-full border-neutral-400 dark:border-neutral-700"
           :class="small ? 'py-1 px-2' : 'py-2 px-3'">
        <div class="whitespace-nowrap truncate">
          <slot v-bind:item="selected">
            <div v-if="multiselect" class="flex flex-wrap space-x-2">
              <span v-if="selected.length === 0" :class="small ? 'p-1 text-xs opacity-50' : 'opacity-50'">
                {{label ? label : 'Bitte wählen...'}}
              </span>
              <template v-else>
                <span v-for="tmp in selected" :key="tmp.id" class="bg-emerald-600 text-xs text-white rounded-full px-2 py-1 mb-1">
                  {{tmp.value}}
                </span>
              </template>
            </div>
            <template v-else>
              <span :class="small ? 'p-1 text-xs' : ''">
                {{selected && selected.value ? selected.value : (selected ? selected : (label ? label : 'Bitte wählen...'))}}
              </span>
            </template>
          </slot>
        </div>
        <svg class="fill-current h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
      </div>
      <div class="absolute left-0 z-50 shadow-lg mt-1 rounded bg-white dark:bg-neutral-900 min-w-min w-full" :class="{ 'hidden': !showMenu }">
        <div class="relative">
          <div v-if="options.length > 5" class="absolute top-0 left-0 w-full p-1 bg-neutral-200 dark:bg-neutral-800">
            <input v-model="filterKey"
                   @click.stop=""
                   ref="search"
                   class="bg-white dark:bg-neutral-900 border-neutral-500 dark:border-neutral-700"
                   placeholder="Suchen..."/>
          </div>
          <div class="dropdown overflow-y-auto min-w-full border border-neutral-300 dark:border-neutral-700" :class="{'pt-12' : options.length > 5}">
            <div
              v-for="(item, index) in filteredOptions"
              :key="index"
              class="flex items-center even:bg-neutral-100 dark:even:bg-neutral-800 hover:bg-primary dark:hover:bg-black hover:text-white"
              :class="[isActive(item) ? 'font-bold bg-neutral-100 dark:bg-neutral-900' : '', wasActive(item) ? 'bg-primary even:bg-primary dark:bg-black dark:even:bg-black text-white' : '']"
            >
              <a
                v-if="item.href"
                :href="item.href"
                :class="[item.parent ? (item.parent.parent ? 'pl-10' : 'pl-6') : '']"
                class="text-sm p-2 block whitespace-nowrap cursor-pointer truncate w-full"
                @click.stop.prevent="itemChanged(item)"
                @mouseover="activeHelper = false"
              >
                <strong>{{ item.label }}</strong> {{ item.description }}
              </a>
              <div
                v-else
                :class="[item.parent ? (item.parent.parent ? 'pl-10' : 'pl-6') : '']"
                class="text-sm p-2 block whitespace-nowrap cursor-pointer truncate w-full"
                @click.stop.prevent="itemChanged(item)"
                @mouseover="activeHelper = false"
              >
                <slot v-bind:item="item">
                  {{ item && item.value ? item.value : item }}
                </slot>
              </div>
              <svg v-if="isActive(item)"
                   class="h-5 w-5 mr-2"
                   x-description="Heroicon name: check"
                   xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 20 20"
                   fill="currentColor"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"/></svg>
            </div>
          </div>
          <div v-if="multiselect">
            <button class="rounded-none rounded-b-lg primary w-full text-xs">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { clone, isEqual } from 'lodash'

export default {
	props: ['value', 'options', 'disabled', 'label', 'multiselect', 'small', 'mandatory'],
	data () {
		return {
			showMenu: false,
			activeHelper: true,
			filterKey: null,
			editValue: null
		}
	},
	computed: {
		isFlat () {
			return this.options.find(o => o.id !== undefined) !== undefined
		},
		getFilterKey () {
			return this.filterKey
		},
		selected () {
			if (this.multiselect) {
				return this.editValue.map(v => {
					if (this.isFlat) {
						return this.options.find(o => o.id === v)
					}
					return v
				})
			} else {
				if (this.isFlat) {
					return this.options.find(o => o.id === this.editValue)
				}
				return this.editValue
			}
		},
		filteredOptions () {
			let items = this.options
			if (this.getFilterKey) {
				let filterKey = this.filterKey.toLowerCase()
				if (this.isFlat) {
					items = items.filter(function (row) {
						return Object.keys(row).some(function (key) {
							return String(row[key]).toLowerCase().indexOf(filterKey) > -1
						})
					})
				} else {
					items = items.filter(item => String(item.value || item).toLowerCase().indexOf(filterKey) > -1)
				}
			}
			return this.getFilterKey ? items : this.options
		}
	},
	methods: {
		redirectToMeasure (href) {
			if (href) {
				this.$router.push({ path: href })
			}
		},
		itemChanged (item) {
			this.$emit('change')
			if (this.multiselect) {
				if (this.isActive(item)) {
					let index = -1
					if (item.id) {
						index = this.editValue.indexOf(item.id)
					} else {
						index = this.editValue.indexOf(item)
					}
					this.editValue.splice(index, 1)
					this.$emit('input', this.editValue)
				} else {
					if (item.id) {
						this.editValue.push(item.id)
					} else {
						this.editValue.push(item)
					}
					this.$emit('input', this.editValue)
				}
			} else {
				this.showMenu = false
				this.activeHelper = true
				this.filterKey = null

				if (this.isActive(item) && this.mandatory === undefined) {
					this.$emit('input', null)
				} else {
					if (item.id) {
						this.$emit('input', item.id)
					} else {
						this.$emit('input', item)
					}
				}
			}
			this.$emit('changed')
		},
		isActive (item) {
			if (this.multiselect) {
				if (item.id) {
					return this.editValue.includes(item.id)
				}
				let contains = this.editValue.some(elem => {
					return isEqual(elem, item)
				})

				return contains
			} else {
				if (item.id) {
					return this.editValue === item.id
				}
				return this.editValue === item
			}
		},
		wasActive (item) {
			if (item.id) {
				return this.editValue === item.id && this.activeHelper
			}
			return this.editValue === item && this.activeHelper
		}
	},
	watch: {
		value: {
			handler (val) {
				if (this.multiselect && val === undefined) {
					this.editValue = []
				} else {
					this.editValue = clone(val)
				}
			},
			deep: true,
			immediate: true
		}
	}
}
</script>
