import ApiClient from '@/assets/js/ApiClient.js'
import Crud from '../../crud'
import { i18n } from '@/main.js'
var crud = new Crud('results/data')

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    neededCollections () {
      return ['results/measures']
    },
    actions () {
      return [
        { label: 'Server Cache erneuern', action: 'refreshCache' },
        { label: 'Browser cache erneuern', action: 'refreshLocalCache' }
      ]
    },
    filters (state, getters, rootState, rootGetters) {
      return [
        {
          name: 'Jahre',
          options: getters.years.map(y => { return { id: y, value: y } }),
          field: 'attributes__year'
        },
        {
          name: 'Messungen',
          options: rootGetters['results/measures/all'].map(m => {
            const label = m.is_parent_measure ? '' : m.label ? ` - ${m.label}` : ' - (no label)'
            const inheritData = !m.is_parent_measure
              ? m.inherit_data ? ' [Inherits data]' : ' [Does not inherit data]'
              : ''
            const newId = m.is_parent_measure ? m.id : m.inherit_data ? m.parent_measure_id : m.id
            return {
              id: newId,
              value: `${m.name}${label}${inheritData}`
            }
          }),
          field: 'measure'
        }
      ]
    },
    years () {
      var currentYear = new Date().getFullYear(); var years = []
      var startYear = 2018
      while (startYear <= currentYear) {
        years.unshift(startYear++)
      }
      return years
    },
    fields (state, getters, rootState, rootGetters) {
      const getCCById = rootGetters['accounts/contenttypes/getById']
      return [
        { text: i18n.t('created'), value: 'created', format: 'DateTime', type: 'DateTime', readOnly: true, hideCreate: true, width: 160 },
        { text: i18n.t('measure'), value: 'measure_name', width: 200 },
        {
          text: 'Attributes',
          value: 'attributes',
          width: 600,
          type: 'json',
          renderItem: (value) => {
            var tmp = []
            Object.keys(value).forEach(key => {
              tmp.push(`${key}: ${value[key]}`)
            })
            return tmp.join(' | ')
          }
        },
        {
          text: i18n.t('object'),
          format: 'Link',
          value: 'content_object.name',
          order: 'object_id',
          readOnly: true,
          hideCreate: true,
          width: -1,
          getLink: (item) => { const cc = getCCById(item.content_type); return `/${cc.app_label}/${cc.model}s/${item.content_object.id}` },
          getDescription: (item) => item.content_object.name,
          showSelect: true
        }
      ]
    },
    getByMeasureAndObjectId: (state) => (measure, object) => { return state.all.filter(o => o.object_id === parseInt(object) && o.measure === measure) }
  },
  mutations: {
    ...crud.mutations
  },
  methods: {
    ...crud.methods
  },
  actions: {
    ...crud.actions,
    async refreshCache ({ dispatch }) {
      dispatch('startLoading', null, { root: true })
      await ApiClient.post('cache/refresh/')
      dispatch('addNotification', { title: 'Erfolgreich', message: 'Server Cache wurde erneuert' }, { root: true })
      dispatch('endLoading', null, { root: true })
    },
    async refreshLocalCache ({ dispatch }) {
      try {
        dispatch('startLoading', null, { root: true })
        const response = await ApiClient.get('results/measures/')
        const measureIds = response.data.map(measure => measure.id)

        await Promise.all(
          measureIds.map(measureId =>
            ApiClient.post(`results/measures/${measureId}/remove_data_cache/`)
              .then(() => console.log(`${measureId} removed`))
              .catch(() => console.log(`${measureId} failed`))
          )
        )

        dispatch('addNotification', { title: 'Erfolgreich', message: 'Browser Cache wurde erneuert' }, { root: true })
      } catch (e) {
        console.log(e)
      } finally {
        dispatch('endLoading', null, { root: true })
      }
    }
  }
}
