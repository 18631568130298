<style type="text/css">
  .apexcharts-bar-area {
    margin-top: 30px;
  }
</style>
<template>
  <div class="border-2 p-2 h-full w-full bg-white dark:bg-chart-dark dark:border-neutral-600" v-view.once="onceHandler">
    <VueApexCharts
      v-if="visible || print"
      ref="chart"
      width="100%"
      height="100%"
      :options="chartOptions"
      :series="series"
    />
    <chart-menu
      v-if="visible && !readOnly"
      :chart="chart"
    />
    <div v-if="!visible" class="bg-neutral-200 animate-pulse h-full w-full dark:bg-neutral-800" />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import utilsMixin from '@/mixins/utils'
import ChartMenu from '@/components/charts/ChartMenu.vue'
import { mapGetters } from 'vuex'
import { uniqBy } from 'lodash'

export default {
  mixins: [utilsMixin],
  data () {
    return {
      height: 0,
      width: 0,
      visible: false,
      seriesData: [],
      categoriesVar: []
    }
  },
  props: {
    chart: {
      type: Object,
      required: true
    },
    annotations: {
      type: Object,
      required: false,
      default: () => {}
    },
    language: {
      type: String,
      default: 'de'
    },
    print: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      resultBenchmarkForMeasureId: 'results/evaluationStore/resultBenchmarkForMeasureId',
      benchmarkNames: 'results/evaluationStore/benchmarkNames',
      filteredDataForChartId: 'results/evaluationStore/filteredDataForChartId',
      currentEvaluation: 'results/evaluations/detailItem'
    }),
    restrictToFspAnq () {
      const current = this.currentEvaluation.measures
      return current.includes(65) || current.includes(66) || current.includes(101) || current.includes(113) || current.includes(61) || current.includes(62) || current.includes(63)
    },
    data () {
      let resultData = this.$globalData.results.evaluationStore.resultData
      return this.filteredDataForChartId(this.chart.i, this.chart.filters, resultData)
    },
    title () {
      return this.translate(this.chart, 'title', this.chart.languageTabTitle ? this.chart.languageTabTitle : this.language)
    },
    subtitle () {
      return this.translate(this.chart, 'subtitle', this.chart.languageTabSubtitle ? this.chart.languageTabSubtitle : this.language)
    },
    getWidth () {
      return this.width
    },
    getTitle () {
      return this.splitTitle(this.title, this.getWidth, { fontSize: '20px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    series () {
      return this.seriesData
    },
    splitSeries () {
      return this.chart.splits.length > 0 ? this.chart.splits[0].data : []
    },
    selectedSeries () {
      return this.chart.data
    },
    getSubTitle () {
      return this.splitTitle(this.subtitle, this.getWidth, { fontSize: '14px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    categories () {
      return this.categoriesVar
    },
    chartOptions () {
      return {
        title: {
          text: this.getTitle,
          align: 'center',
          offsetY: this.readOnly ? 0 : 35,
          margin: 35,
          style: {
            fontSize: '20px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorSheme === 'dark' ? '#3a92cf' : '#333333'
          }
        },
        subtitle: {
          text: this.getSubTitle,
          align: 'center',
          margin: this.getSubTitle ? 5 : 0,
          offsetX: 0,
          offsetY: this.getTitle.length * 25 + (this.readOnly ? 25 : 55),
          style: {
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorSheme === 'dark' ? '#3a92cf' : '#777777'
          }
        },
        chart: {
          type: 'boxPlot',
          fontFamily: 'Nunito, sans-serif',
          toolbar: {
            show: false,
            offsetX: -30
          },
          events: {
            mounted: (chart) => {
              if (this.$refs.chart === undefined) return
              this.height = this.$refs.chart.$el.clientHeight
              this.width = this.$refs.chart.$el.clientWidth
              chart.windowResizeHandler()
            },
            updated: () => {
              if (this.$refs.chart === undefined) return
              this.height = this.$refs.chart.$el.clientHeight
              this.width = this.$refs.chart.$el.clientWidth
              if (this.showErrorBars) {
                this.buildErrorBars()
              }
            }
          }
        },
        plotOptions: {
          boxPlot: {
            colors: {
              upper: this.restrictToFspAnq ? '#559861' : '#1d5a84',
              lower: this.restrictToFspAnq ? '#74B86B' : '#9ab5c2'
            }
          }
        },
        xaxis: {
          categories: this.categoriesVar,
          labels: {
            trim: true,
            maxHeight: 200,
            formatter: function (val) {
              return val
            }
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          }
        },
        yaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            trim: true,
            formatter: function (val) {
              return val.toFixed(2)
            }
          }
        }
      }
    }
  },
  methods: {
    buildErrorBars () {
      const groups = document.querySelectorAll('g[className="apexcharts-bar-goals-groups"]')
      for (var i = 0; i < groups.length; ++i) {
        var group = groups[i]

        const errorBars = group.querySelectorAll('line[stroke="#aaaaaa"]')

        if (errorBars.length === 2) {
          var errorBar1 = errorBars[0]
          var errorBar2 = errorBars[1]
          var line = document.createElement('line')
          const yVal = errorBar1.y1.baseVal.value + ((errorBar1.y2.baseVal.value - errorBar1.y1.baseVal.value) / 2)
          line.id = `${errorBar1.id}_${errorBar2.id}`
          line.setAttribute('stroke', '#aaaaaa')
          line.setAttribute('stroke-width', '2')
          line.setAttribute('x1', errorBar1.x1.baseVal.value)
          line.setAttribute('x2', errorBar2.x1.baseVal.value)
          line.setAttribute('y1', yVal)
          line.setAttribute('y2', yVal)
          line.setAttribute('stroke-dasharray', 0)
          line.setAttribute('stroke-linecap', 'butt')
          line.setAttribute('transform', 'matrix(1,0,0,1,0,0)')
          // shorten the bars
          errorBar1.setAttribute('y1', errorBar1.y1.baseVal.value + 4)
          errorBar1.setAttribute('y2', errorBar1.y2.baseVal.value - 4)
          errorBar2.setAttribute('y1', errorBar2.y1.baseVal.value + 4)
          errorBar2.setAttribute('y2', errorBar2.y2.baseVal.value - 4)

          group.append(line)
          var newVal = group.innerHTML
          group.innerHTML = newVal
        }
      }
    },
    setupData () {
      if (!this.visible || this.data.length <= 0) {
        this.seriesData = []
        return
      }

      this.categoriesVar = []

      var result = []

      result = this.buildSplitSeries(result, this.data)

      this.seriesData = [{ data: result }]
    },
    calculateSplitResults (serieKey, splitValue, data) {
      var output = data.filter(value => {
        var result = false

        if (splitValue.customFunc) {
          result = splitValue.customFunc(value.attributes[splitValue.key])
        } else {
          result = value.attributes[splitValue.key] && String(value.attributes[splitValue.key]) === String(splitValue.value)
        }

        return result
      })

      var final = output.map(val => val.attributes[serieKey])
      return final
    },
    buildSplitSeries (result, data) {
      const hasMultipleMeasures = uniqBy(this.selectedSeries, v => v.measure ? v.measure.id : 0).length > 1
      this.splitSeries.forEach((splitValue) => {
        this.selectedSeries.forEach((serie) => {
          var obj = {}
          if (hasMultipleMeasures) {
            obj.name = `${splitValue.measure.name}: ${splitValue.label}`
          } else {
            obj.name = this.translate(splitValue, 'label', this.language)
          }
          obj = []

          var tmp = this.calculateSplitResults(serie.key, splitValue, data)
          const boxPlotData = Object.values(tmp).sort((a, b) => a - b).filter(value => value != null)

          // Step 2: Calculate the median (Q2)
          const median = (boxPlotData.length % 2 === 0)
            ? (boxPlotData[boxPlotData.length / 2 - 1] + boxPlotData[boxPlotData.length / 2]) / 2
            : boxPlotData[Math.floor(boxPlotData.length / 2)]

          // Step 3: Calculate Q1 (First Quartile)
          const lowerHalf = boxPlotData.slice(0, Math.floor(boxPlotData.length / 2))
          const q1 = (lowerHalf.length % 2 === 0)
            ? (lowerHalf[lowerHalf.length / 2 - 1] + lowerHalf[lowerHalf.length / 2]) / 2
            : lowerHalf[Math.floor(lowerHalf.length / 2)]

          // Step 4: Calculate Q3 (Third Quartile) using Tukey's method
          const upperHalf = boxPlotData.slice(Math.ceil(boxPlotData.length / 2))
          const n = upperHalf.length
          const lowerMidpoint = Math.floor(n / 2)
          const upperMidpoint = Math.floor((n + 1) / 2)
          const q3 = (n % 2 === 0)
            ? (upperHalf[lowerMidpoint - 1] + upperHalf[upperMidpoint]) / 2
            : upperHalf[upperMidpoint]

          const iqr = q3 - q1
          const lowerWhisker = q1 - 1.5 * iqr
          const upperWhisker = q3 + 1.5 * iqr

          this.categoriesVar.push(splitValue.value)

          obj.push({
            x: this.translate(splitValue, 'label', this.language),
            y: [lowerWhisker, q1, median, q3, upperWhisker]
          })

          result = [...result, ...obj]
        })
      })

      return result
    },
    onceHandler () {
      this.visible = true
    }
  },
  components: {
    VueApexCharts,
    ChartMenu
  },
  watch: {
    selectedSeries: {
      handler () {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    splitSeries: {
      handler () {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    groups: {
      handler () {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    data: {
      handler () {
        this.setupData()
      }
    },
    visible: {
      handler () {
        this.setupData()
      }
    }
  }
}
</script>
