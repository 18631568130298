
const globalData = {
    exceedData: 130000,
    anqOnly: [113, 61, 62, 63],
    fspOnly: [65, 66, 101],
    moniqOnly: [14],
    results: {
        evaluationStore: {
            fullData: { },
            resultData: { },
            filteredData: { }       
        }
    },
  };

export default globalData;

