import Crud from '../../crud'

var crud = new Crud('patzu/events')

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    hasHistory: () => true,
    contentType: (state, getters, rootState, rootGetters) => rootGetters['accounts/contenttypes/getByName']('patzu.eventmeasure').id,
    neededCollections () {
      return ['patzu/measures']
    },
    filters (state, getters, rootState, rootGetters) {
      return [
        {
          name: 'Kategorien',
          options: rootGetters['patzu/hospitals/categories'],
          field: 'category'
        },
        {
          name: 'Jahre',
          options: rootGetters.years.map(y => { return { id: y, value: y } }),
          field: 'year'
        },
        {
          name: 'Messung',
          options: rootGetters['patzu/measures/all'].map(data => { return { id: data.id, value: data.name } }),
          field: 'measure_ids'
        }
      ]
    },
    fields () {
      return [
        { text: 'Messung', value: 'measure', type: 'rel_select', getterPath: 'patzu/measures', hideList: true },
        { text: 'Messung', value: 'measure_name', order: 'measure', hideEdit: true, hideCreate: true, width: 200 },
        {
          text: 'Typ',
          value: 'typ',
          type: 'select',
          width: 220,
          options: [
            { id: 1, value: 'Bestellung' },
            { id: 2, value: 'Deklaration' },
            { id: 3, value: 'Sonstiges' },
            { id: 4, value: 'Datenprüfung' },
            { id: 5, value: 'Datenübermittlung' }
          ],
          showSelect: true
        },
        { text: 'Name', value: 'name', width: -1, showSelect: true },
        { text: 'w² Task', value: 'is_w2_task', width: 80, format: 'BooleanView', type: 'boolean' },
        { text: 'Start', value: 'start_date', type: 'date', format: 'Date', width: 160, showSelect: true },
        { text: 'Ende', value: 'end_date', type: 'date', format: 'Date', width: 160, showSelect: true }
      ]
    }
  },
  mutations: {
    ...crud.mutations
  },
  actions: {
    ...crud.actions
  }
}
