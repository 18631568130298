<template>
  <div class="w-full flex flex-wrap">
    <div v-for="field in getFields" :key="field.value" :class="[field.style ? field.style : 'w-full']">
      <h2 v-tip="'measures'" @mouseleave="debug(editValues)">{{field.text}}</h2>
      <div v-tip="'measures'" v-if="field.help" class="my-2 text-neutral-600 dark:text-neutral-400 italic">{{field.help}}</div>
      <template v-if="field.renderItem && field.readOnly">
        <span class="text-lg">{{field.renderItem(editValues[field.value])}}</span>
      </template>
      <div v-else-if="field.readOnly || (field.readOnlyFunc && field.readOnlyFunc(editValues))" class="bg-neutral-200 dark:bg-neutral-900 p-3">
        <template v-if="field.options">
          {{field.options.find(item => item.id === getValue(editValues, field.value)).value}}
        </template>
        <template v-else>
          {{getValue(editValues, field.value)}}
        </template>
      </div>
      <div class="relative" v-else-if="field.type === 'rel_select'">
        <div v-if="field.canAdd && !isNewItem" @click="addItem(field.getterPath, field.relationField, editValues.id)" class="absolute right-2 -top-9 rounded-full bg-black w-8 h-8 text-white flex items-center justify-center cursor-pointer hover:bg-neutral-600">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
        </div>
        <relation-select
          v-model="editValues[field.value]"
          :field="field.value"
          :getterPath="field.getterPath"
          :query=" (typeof field.query === 'function') ? field.query(editValues) : field.query"
          :hideAdd="field.canAdd"
          :isReadOnly="field.isReadOnly"
          :initQuery="(typeof field.initQuery === 'function') ? field.initQuery(editValues) : field.initQuery"
          :isMulti="field.isMulti"
          :customFields="field.customFields"
          :condition="applyCondition"
        />
      </div>
      <template v-else-if="field.type === 'date'">
        <datepicker
          @input="dateSelected($event, field.value)"
          :value="editValues[field.value]"
          :format="'dd.MM.yyyy'"
          :language="language"
          :monday-first="true"
          :input-class="'cursor-pointer'"
        />
      </template>
      <template v-else-if="field.type === 'select'">
        <v-select
          v-if="getOptions(field.value)"
          v-model="editValues[field.value]"
          :label="field.label"
          :options="getOptions(field.value)"
          :itemKey="'value'"
          :valueKey="'id'"
          :multiselect="field.multiselect"
        />
      </template>
      <template v-else-if="field.type === 'translate'">
        <v-input-languages :value="editValues" :labelKey="field.value" :languages="languages.map(l => l.id)"/>
      </template>
      <template v-else-if="field.type === 'boolean'">
        <v-checkbox v-model="editValues[field.value]" class="m-1"/>
      </template>
      <template v-else-if="field.type === 'textarea'">
        <textarea rows="8"
                  v-model="editValues[field.value]"
                  class="m-1"
                  :disabled="field.readOnly"
                  :maxlength="field.maxlength ? field.maxlength : null"></textarea>
        <div v-if="field.maxlength" class="text-xs text-neutral-400">
          {{editValues[field.value] ? editValues[field.value].length : 0}} / {{field.maxlength}}
        </div>
      </template>
      <template v-else-if="field.type === 'json'">
        <div class="flex justify-end mb-1">
          <div @click="toggleJsonView(editValues, field.value)" class="cursor-pointer p-1 bg-secondary hover:bg-opacity-70 text-white rounded-full text-xs w-16 text-center font-bold">
            JSON
          </div>
        </div>
        <template v-if="isJsonView">
          <div class="mb-1 font-bold text-red-600">{{jsonError}}</div>
          <ResizeAuto>
            <template v-slot:default="{resize}">
              <textarea class="resize-none font-mono overflow-hidden" @input="resize" v-model="jsonData" spellcheck="false"></textarea>
            </template>
          </ResizeAuto>
        </template>
        <table v-else class="table-fixed tr:even:bg-neutral-50">
          <thead class="bg-neutral-100 min-w-full divide-y divide-neutral-200">
            <tr class="px-1 py-1 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider whitespace-nowrap">
              <th>Key</th><th>Value</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-neutral-200 text-sm">
            <tr v-for="(value, key) in editValues[field.value]" :key="key" class="even:bg-neutral-50">
              <td class="py-1">{{key}}</td><td class="py-1">{{value}}</td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else-if="field.type === 'component'">
        <component
          v-if="field.values !== undefined"
          :value="valuesInObject(field.values)"
          :options="field.options"
          :item="editValues"
          :is="field.componentName.split('/')[field.componentName.split('/').length - 1]"
          @input="onUpdateValues"
        />
        <div class="relative" v-else>
          <div v-if="field.canAdd" @click="addItem(field.getterPath, field.relationField, editValues.id)" class="absolute right-2 -top-9 rounded-full bg-black w-8 h-8 text-white flex items-center justify-center cursor-pointer hover:bg-neutral-600">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
          </div>
          <component
            v-model="editValues[field.value]"
            :item="editValues"
            :is="field.componentName.split('/')[field.componentName.split('/').length - 1]"
          />
        </div>
      </template>
      <template v-else-if="field.type === 'link'">
        <a class="text-secondary hover:underline font-bold p-1" :href="field.getLink(editValues)">{{editValues[field.value]}}</a>
      </template>
      <template v-else>
        <input
          v-model="editValues[field.value]"
          :id="field.value"
          :type="field.type"
          :placeholder="field.text"
          :maxlength="field.max_length || null"
          :required="field.required"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VInputLanguages from '@/components/v-input-languages'
import Datepicker from 'vuejs-datepicker'
import VCheckbox from '@/components/v-checkbox'
import VSelect from '@/components/v-select'
import Vue from 'vue'
import { clone, get } from 'lodash'
import RelationSelect from './RelationSelect.vue'
import { de, fr, it } from 'vuejs-datepicker/dist/locale'
import dayjs from 'dayjs'
import ResizeAuto from '@/components/ResizeAuto'
export default {
	components: {
		'v-checkbox': VCheckbox,
		'v-select': VSelect,
		RelationSelect,
		Datepicker,
		ResizeAuto,
		VInputLanguages
	},
	props: {
		fields: {
			type: [Array, Object],
			required: true
		},
		values: Object,
		isNewItem: Boolean,
		condition: Object
	},
	data () {
		return {
			// editValues - my values that I need, it's email, phone, etc
			editValues: null,
			options: {},
			isJsonView: false,
			jsonData: '',
			jsonError: ''
		}
	},
	computed: {
		...mapGetters({
			user: 'user',
			languages: 'languages'
		}),
		language () {
			if (!this.user) {
				return de
			}
			if (this.user.language === 2) {
				return fr
			}
			if (this.user.language === 3) {
				return it
			}
			return de
		},
		getFields () {
			let tmpFields = []
			if (this.isNewItem) {
				tmpFields = this.fields.filter(f => f.hideCreate === undefined || f.hideCreate === false || (typeof f.hideCreate === 'function' && f.hideCreate(this.editValues) === false))
			} else {
				tmpFields = this.fields.filter(f => f.hideEdit === undefined || f.hideEdit === false || (typeof f.hideEdit === 'function' && f.hideEdit(this.editValues) === false))
			}
			return tmpFields.filter(f => f?.hideLists != true)
		},
		valuesInObject () {
			return values => {
				let result = {}
				values.forEach(v => {
					result[v] = this.editValues[v]
				})
				return result
			}
		},
		getOptions () {
			return fieldValue => {
				return this.options[fieldValue]
			}
		},
		applyCondition () {
			if (!this.isInMoniqHospitals()) return true
			if (!(this.condition && this.editValues)) return false
			if (!(this.condition.path in this.editValues)) return false
			return (this.editValues[this.condition.path] === this.condition.id)
		}
	},
	methods: {
		debug () {
		},
		isInMoniqHospitals () {
			return this.$route.path.endsWith('moniq/hospitals/+')
		},
		toggleJsonView (editValue, field) {
			if (this.isJsonView) {
				try {
					editValue[field] = JSON.parse(this.jsonData)
					this.isJsonView = false
					this.jsonError = ''
					this.jsonData = ''
				} catch (error) {
					this.jsonError = error.message
				}
			} else {
				this.jsonData = JSON.stringify(editValue[field], undefined, 2)
				this.isJsonView = true
			}
		},
		dateSelected (event, value) {
			const d = dayjs(event).format('YYYY-MM-DD')
			Vue.set(this.editValues, value, d)
		},
		addItem (getterPath, field, id) {
			this.$router.push({ path: `/${getterPath}/+?${field}=${id}` })
		},
		getValue (value, prop) {
			return get(value, prop)
		},
		onUpdateValues (values) {
			Object.keys(values).forEach(key => {
				Vue.set(this.editValues, key, values[key])
			})
		},
		registerComponents () {
			const components = this.fields.filter(f => f.type === 'component')
			components.forEach(c => {
				const component = import('@/components/display/' + c.componentName + '.vue')
				const tmpName = c.componentName.split('/')
				const componentName = tmpName[tmpName.length - 1]
				Vue.component(componentName, () => ({
					component: component
				}))
			})
		},
		async loadOptions () {
			for (const field of this.getFields) {
				if (field.type === 'select') {
					Vue.set(this.options, field.value, field.optionsFunc ? await field.optionsFunc(this.editValues) : field.options)
				}
			}
		}
	},
	created () {
		this.registerComponents()
	},
	mounted () {

	},
	watch: {
		editValues: {
			handler (val) {
				this.$store.commit('setEditValues', val)
				this.loadOptions()
			},
			deep: true,
			immediate: true
		},
		values: {
			handler (val) {
				this.editValues = clone(val)
			},
			deep: true,
			immediate: true
		}
	}
}
</script>
