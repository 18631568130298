import { render, staticRenderFns } from "./EditEvaluation.vue?vue&type=template&id=81082272&scoped=true&"
import script from "./EditEvaluation.vue?vue&type=script&lang=js&"
export * from "./EditEvaluation.vue?vue&type=script&lang=js&"
import style0 from "./EditEvaluation.vue?vue&type=style&index=0&id=81082272&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81082272",
  null
  
)

export default component.exports