<style scoped>
.fix-height{
  max-height: calc(100vh - theme('spacing[96]'))
}
</style>

<template>
  <div class="relative">
    <div v-if="!showFilter" class="flex flex-col items-start gap-4 mt-3">
      <div class="w-full" v-for="(filter, index) in editFilters" :key="index">
        <div class="flex justify-between">
          <label class="mb-2">{{filter.title}}</label>
          <div
            class="h-6 w-6 bg-red-600 rounded-full flex items-center justify-center hover:bg-red-800 cursor-pointer shrink-0"
            @click="removeFilter(index)"
          >
            <svg class="fill-current text-white h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z"/></svg>
          </div>
        </div>

        <div v-if="filter.type === 'date_range'"  class="flex flex-col items-start">
          <p class="date-range-filter-info pb-2 text-xs text-orange-400">{{ getDateRangeInfoText(index) }}</p>
          <div class="flex items-center">
            <div>
              <label
                :class="activeFilters[index].submetricIsActive?.dateRange ? activeFieldStyling : inactiveFieldStyling"
              >
                {{ capitalizeFirstLetter($t('from'))}}
              </label>
              <datepicker
                @input="dateSelected($event, 'from', index, filter.key)"
                :value="activeFilters[index].from"
                :format="'dd.MM.yyyy'"
                :monday-first="true"
                :input-class="`cursor-pointer w-32 ${activeFilters[index].submetricIsActive?.dateRange ? activeFieldStyling : inactiveFieldStyling}`"
                calendar-class="'opacity-100'"
              ></datepicker>
            </div>
            <div
              class="ml-2">
              <label
                :class="activeFilters[index].submetricIsActive?.dateRange ? activeFieldStyling : inactiveFieldStyling"
              >
                {{ capitalizeFirstLetter($t('to')) }}
              </label>
              <div class="flex items-center">
                <datepicker
                  @input="dateSelected($event, 'until', index, filter.key)"
                  :value="activeFilters[index].until"
                  :format="'dd.MM.yyyy'"
                  :monday-first="true"
                  :input-class="`cursor-pointer w-32 ${activeFilters[index].submetricIsActive?.dateRange ? activeFieldStyling : inactiveFieldStyling}`"
                  calendar-class="'opacity-100'"
                ></datepicker>
                <div
                  @click="setToday(index)"
                  class="
                    ml-2
                   text-blue-500
                   font-bold
                   hover:text-blue-800 cursor-pointer shrink-0
                  "
                  :class="activeFilters[index].submetricIsActive?.dateRange ? activeFieldStyling : inactiveFieldStyling"
                >
                  {{ $t('till-today') }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="mt-2"
            :class="activeFilters[index].submetricIsActive?.daysBack ? activeFieldStyling : inactiveFieldStyling"
          >
            <label>{{ $t('count-days') }}</label>
            <input
              @input="(event) => {switchActiveDateFilterToDaysBack(index); debouncedRefresh(event)}"
              v-model="activeFilters[index].daysBack"
            >
          </div>
        </div>
        <v-select
          v-else  v-model="activeFilters[index]"
          :label="$t('select-title-text')"
          :options="filter.options"
          :multiselect="true"
          @changed="filterUpdated(filter.global, index)"
        />
        <v-checkbox
           v-if="hasMultipleMeasures && !checkiterable(activeFilters[index])"
           class="mt-2"
           autocomplete="off"
            v-model="activeFilters[index].global"
           @input="filterUpdated($event, index)"
        >
          {{ $t('commit-to-all-measures') }}
        </v-checkbox>
        <v-checkbox
           v-else-if="hasMultipleMeasures && checkiterable(activeFilters[index]) && activeFilters[index].length > 0"
           class="mt-2"
           autocomplete="off"
            v-model="activeFilters[index][0].global"
           @input="filterUpdated($event, index)"
        >
          {{ $t('commit-to-all-measures') }}
        </v-checkbox>

      </div>
      <div class="flex w-full">
        <button v-if="!isGlobalFilter" class="primary w-full mr-2" :disabled="activeFilters.length === 0" @click="filterChanged()">
          {{ pickTranslationFromJson ? pickTranslationFromJson(language, 'refresh-chart') : $t('refresh-chart') }}
        </button>
        <button v-tip="'evaluations'" class="primary w-full" @click="showFilter=true">
          {{ pickTranslationFromJson ? pickTranslationFromJson(language, 'add-filter') : $t('add-filter') }}
        </button>
      </div>
    </div>
    <div v-else class="p-4 w-full">
      <div class="overflow-y-auto fix-height scrollbar">
        <div v-for="(serie, name, index) in series" :key="`attr_group_${index}_${name}`">
          <div
            v-if="openIndex === index || openIndex === -1"
            @click="openIndex === index ? openIndex = -1 : openIndex = index"
            class="bg-secondary hover:bg-opacity-75 text-white border-b border-neutral-400 p-2 cursor-pointer flex"
            :class="{'border-t': index === 0, 'justify-between': openIndex === -1}"
          >
            <svg v-if="openIndex === index" xmlns="http://www.w3.org/2000/svg" class="w-4 fill-current transform rotate-180 mr-2" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
            {{serie[0] != null ? translate(serie[0], 'title', language) : name}}
            <svg v-if="openIndex === -1" xmlns="http://www.w3.org/2000/svg" class="w-4 fill-current" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
          </div>
          <div v-if="openIndex===index">
            <div
              v-for="(element, index) in serie"
              :hidden="element.hide_in_graph || element.hide_in_only"
              class="hover:bg-opacity-75 text-white border-b border-neutral-400 p-2 cursor-pointer"
              :class="[editFilters.find(f => f.key === element.key) ? 'bg-emerald-600' : 'bg-secondary',(element.hide_in_graph || element.hide_in_only)? '':'flex']"
              @click="toggleFilter(element)"
              :key="`attr_${index}_`"
            >
              <template v-if="!element.hide_in_graph && !element.hide_in_only">
                {{ translate(element, 'label', language) }}
              </template>
            </div>
          </div>
        </div>
      </div>
      <button class="primary w-full mt-2 font-bold" @click="showFilter = false">{{ pickTranslationFromJson ? pickTranslationFromJson(language, 'save') : $t('save') }}</button>
    </div>
  </div>
</template>

<script>
import { DATE_FORMATS } from '@/constants.js'
import dayjs from 'dayjs'
import vSelect from '../../v-select.vue'
import Datepicker from 'vuejs-datepicker'
import VCheckbox from '@/components/v-checkbox'
import utilsMixin from '@/mixins/utils'
import debounce from 'lodash'

export default {
  components: { vSelect, Datepicker, VCheckbox },
  mixins: [utilsMixin],
  props: ['series', 'filters', 'measure', 'hasMultipleMeasures', 'isGlobalFilter', 'language', 'pickTranslationFromJson'],
  data () {
    return {
      openIndex: -1,
      editFilters: [],
      activeFilters: [],
      showFilter: false,
      inactiveFieldStyling: 'opacity-10',
      activeFieldStyling: 'opacity-100'
    }
  },
  methods: {
    setToday (index) {
      const tmp = this.activeFilters[index]
      tmp.until = dayjs().format('YYYY-MM-DD')
      this.switchActiveDateFilterToDateRange(index)
      this.$set(this.activeFilters, index, tmp)
      this.filterChanged()
    },
    dateSelected (event, value, index) {
      const d = dayjs(event).format('YYYY-MM-DD')
      const tmp = this.activeFilters[index]
      if (value === 'from' || value === 'until') {
        this.switchActiveDateFilterToDateRange(index)
      }
      tmp[value] = d
      this.$set(this.activeFilters, index, tmp)
      this.filterChanged()
    },
    switchActiveDateFilterToDateRange (filterIndex) {
      const dateFilter = this.activeFilters[filterIndex]
      if (dateFilter.submetricIsActive) {
        dateFilter.submetricIsActive.dateRange = true
        dateFilter.submetricIsActive.daysBack = false
      }
    },
    switchActiveDateFilterToDaysBack (filterIndex) {
      const dateFilter = this.activeFilters[filterIndex]
      if (dateFilter.submetricIsActive) {
        dateFilter.submetricIsActive.dateRange = false
        dateFilter.submetricIsActive.daysBack = true
      }
    },
    removeFilter (index) {
      this.editFilters.splice(index, 1)
      this.activeFilters.splice(index, 1)
      this.filterChanged()
    },
    toggleFilter (filter) {
      const index = this.editFilters.findIndex(f => f.key === filter.key)
      if (index > -1) {
        this.editFilters.splice(index, 1)
        this.activeFilters.splice(index, 1)
      } else {
        var addedFilter = this.setupFilter(filter)
        if (addedFilter.type === 'date_range') {
          this.activeFilters.push({
            submetricIsActive: { dateRange: true, daysBack: true },
            from: addedFilter.from,
            until: addedFilter.until,
            daysBack: addedFilter.daysBack,
            key: addedFilter.key,
            type: addedFilter.type,
            keyLabel: addedFilter.title,
            global: addedFilter.global,
            measure: addedFilter.measure
          })
        } else {
          this.activeFilters.push([])
        }
      }
    },
    filterChanged () {
      const activeFiltersWithInactiveSubmetricsRemoved = [].concat(...this.activeFilters).map((filter) => {
        // Remove inactive submetrics from filters of type 'date_range'
        if (filter.type === 'date_range') {
          return {
            ...filter,
            from: filter.submetricIsActive?.dateRange ? filter.from : undefined,
            until: filter.submetricIsActive?.dateRange ? filter.until : undefined,
            daysBack: filter.submetricIsActive?.daysBack ? filter.daysBack : null
          }
        } else {
          return filter
        }
      })
      this.$emit('on-filter-changed', activeFiltersWithInactiveSubmetricsRemoved)
    },
    filterUpdated (global, index) {
      const filters = this.activeFilters[index]
      if (this.checkiterable(filters)) {
        filters.forEach(x => { x.global = global })
      } else {
        filters.global = global
      }
      this.filterChanged()
    },
    setupFilter (filter, origFilter) {
      const getCurrentLabel = (optionData) => {
        let currentTitle = ''
        if (this.language === 'it' && optionData.label_it) {
          currentTitle = optionData.label_it
        } else if (this.language === 'fr' && optionData.label_fr) {
          currentTitle = optionData.label_fr
        } else {
          currentTitle = optionData.label
        }
        return currentTitle
      }

      if (filter.type === 'date_range') {
        const items = filter.data.map(v =>
          dayjs(v.value, DATE_FORMATS)
            .format('YYYY-MM-DD')).sort((a, b) => dayjs(b, 'YYYY-MM-DD').isAfter(dayjs(a, 'YYYY-MM-DD')) ? -1 : 1)

        this.editFilters.push({
          key: filter.key,
          title: getCurrentLabel(filter),
          type: filter.type,
          from: origFilter ? origFilter.from || items[0] : items[0],
          until: origFilter ? origFilter.until || items[items.length - 1] : items[items.length - 1],
          daysBack: origFilter ? origFilter.daysBack || null : null,
          global: origFilter?.global ? origFilter.global : true,
          measure: origFilter?.measure ? origFilter.measure : this.measure
        })
      } else {
        const getCurrentFilterOptions = () => (
          filter.data.filter(checkDataExist => checkDataExist.value !== '').map(optionData => {
            return {
              key: filter.key,
              questionForFilterInChart: filter.label,
              keyLabel: getCurrentLabel(optionData), // redundant now
              dataValue: optionData.value, // redundant now
              value: getCurrentLabel(optionData),
              global: origFilter?.global ? origFilter.global : true,
              measure: origFilter?.measure ? origFilter.measure : this.measure
            }
          })
        )
        this.editFilters.push({
          key: filter.key,
          title: getCurrentLabel(filter),
          values: filter.data,
          options: getCurrentFilterOptions(),
          type: filter.type,
          global: origFilter?.global ? origFilter.global : true,
          measure: origFilter?.measure ? origFilter.measure : this.measure
        })
      }
      return this.editFilters[this.editFilters.length - 1]
    },
    capitalizeFirstLetter (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    getDateRangeInfoText (filterIndex) {
      if (this.activeFilters[filterIndex].submetricIsActive) {
        const dateRangeIsActive = this.activeFilters[filterIndex].submetricIsActive.dateRange
        const daysBackIsActive = this.activeFilters[filterIndex].submetricIsActive.daysBack
        const baseInfoText = this.$t('date-range-filter-info-base-text')
        const additionalInfoTexts = this.$t('date-range-filter-additional-info-texts')
        if (dateRangeIsActive === daysBackIsActive) {
        // Always only use at most one metric of the date-range filter at a time.
        // So if both or neither of the metrics are active then don't use either one.
          return `${baseInfoText} ${additionalInfoTexts.metricsInactive}`
        } else if (dateRangeIsActive) {
          return `${baseInfoText} ${additionalInfoTexts.dateRangeActive}`
        } else if (daysBackIsActive) {
          return `${baseInfoText} ${additionalInfoTexts.daysBackActive}`
        }
      }
    }
  },
  created () {
    this.debouncedRefresh = debounce(() => {
      this.filterChanged()
    }, 500)
    this.checkiterable = (filters) => {
      return (typeof filters[Symbol.iterator] === 'function')
    }
  },
  onBeforeUnmount () {
    this.debouncedRefresh.cancel()
  },
  watch: {
    measure: {
      async handler (val) {
        for (const filter in this.activeFilters) {
          if (this.activeFilters[filter].type === 'date_range') {
            this.activeFilters[filter].measure = val
          } else {
            this.activeFilters[filter][0].measure = val
          }
        }
        this.filterChanged()
      }
    }
  },
  mounted () {
    if (this.filters) {
      this.filters.forEach(filter => {
        Object.values(this.series).forEach(serie => {
          const item = serie.find(s => s.key === filter.key)
          if (item) {
            if (this.editFilters.findIndex(f => f.key === item.key) === -1) {
              this.setupFilter(item, filter)
            }
            // Add Active filters
            const index = this.activeFilters.findIndex(f => f.length && f.find(item => item.key === filter.key))
            if (index === -1) {
              if (filter.type === 'date_range') {
                this.activeFilters.push(filter)
              } else {
                this.activeFilters.push([])
                this.activeFilters[this.activeFilters.length - 1].push(filter)
              }
            } else {
              this.activeFilters[index].push(filter)
            }
          }
        })
      })
    }
  }
}
</script>
