<template>
  <div class="z-50">
    <div class="absolute right-3 top-2 z-50" v-click-outside="close">

      <div @click="isOpen = !isOpen" class="cursor-pointer">
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </div>
      <div v-if="isOpen" class="origin-top-right absolute right-0 mt-2 w-52  rounded-md shadow-lg py-1 bg-white dark:bg-neutral-700 ring-1 ring-black dark:ring-neutral-500 ring-opacity-5 focus:outline-none max-h-80 overflow-y-auto">

        <div v-for="c in viewTypes" :key="c.name"
          class="px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer flex items-center"
          @click="updateChartViews(c.name)">
          <span class="w-5 fill-current">
            <svg v-if="chart.viewSettings?.includes(c.name)" class="h-4 w-4 " xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd"><path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"/></svg>
          </span>
          <span>{{c.label}}</span>
        </div>
        <div v-if="viewTypes.length" testlauf class="border-t-2 dark:border-neutral-500"></div>

        <div v-for="c in chartTypes" :key="c.name"
          class="flex items-center px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer"
          @click="changeChart(c.name)">
          <!-- <pre>{{ c }}</pre> -->
          <span class="w-5">
            <svg v-if="chart.type === c.name" class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd"><path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"/></svg>
          </span>
          <span>{{c.label}}</span>
        </div>
        <template v-if="!isPreview">
          <div class="border-t-2 dark:border-neutral-500"></div>
          <div @click="download('svg')" class="block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer">{{ $t('download-svg') }}</div>
          <div @click="download('png')" class="block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer">{{ $t('download-png') }}</div>
          <!-- <div @click="download('csv')" class="block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer">Download CSV</div> -->
          <template v-if="isAdminOrStaff">
          <div class="border-t-2 dark:border-neutral-500"></div>
            <div v-if="hasTabs" @click="moveChart" class="block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer">{{ $t('move-to-tab') }}</div>
            <div @click="editChart" class="block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer">{{ $t('edit') }}</div>
            <div @click="cloneChart" class="block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer">{{ $t('duplicate') }}</div>
            <div @click="deleteChart" class="block px-4 py-1 text-sm text-red-700 dark:text-red-400 hover:bg-red-100 dark:hover:bg-red-800 dark:hover:text-red-100 cursor-pointer border-t-2 dark:border-neutral-500">{{ $t('delete') }}</div>
          </template>
        </template>
      </div>
    </div>

  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  props: {
    chart: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isOpen: false,
      viewTypesDef: [
        { label: this.$t('benchmark'), name: 'benchmark', types: ['mean', 'radar'], visible: () => { return this.hasBenchmark } },
        { label: this.$t('95-errorbars'), name: 'error_bars', types: ['mean'] },
        { label: this.$t('n-less-than-10-color'), name: 'color_less_10', types: ['mean', 'likert', 'bar'] },
        { label: this.$t('horizonal'), name: 'horizontal', types: ['bar', 'stacked', 'stacked-full', 'boxPlot', 'tableChart'] },
        { label: this.$t('show-values'), name: 'datalabels', types: ['radar', 'mean', 'bar', 'stacked', 'stacked-full'] },
        { label: this.$t('stacked-bar-0-100'), name: 'stacked_likert', types: ['likert'] }
      ],
      chartTypesDef: [
        { label: 'Timeline', name: 'timeline', needsConfig: true },
        { label: 'Spider Plot', name: 'radar', needsConfig: true },
        { label: 'Mean Chart', name: 'mean', needsConfig: true, addSkala: () => { return this.hasSkala } },
        { label: 'Likert Chart', name: 'likert', needsConfig: true, visible: () => { return !this.hasDimensions } },
        { label: 'IKA Chart', name: 'ika', needsConfig: true, visible: () => { return this.isStaff } },
        { label: 'Simple Bar', name: 'bar', needsConfig: false },
        { label: 'Stacked Bar', name: 'stacked', needsConfig: false },
        { label: 'Full Stacked Bar', name: 'stacked-full', needsConfig: false }
      ],
      keysToKeep: ['data', 'groups', 'slices', 'splits', 'filters']
    }
  },
  computed: {
    ...mapGetters({
      isStaff: 'isStaff',
      hasBenchmark: 'results/evaluationStore/hasBenchmark',
      resultBenchmarkForMeasureId: 'results/evaluationStore/resultBenchmarkForMeasureId',
      currentEvaluation: 'results/evaluations/detailItem'
    }),
    hasSkala () {
      const arrayofObjects = this.chart.data
      const allObjectsMeetCondition = arrayofObjects.every(obj => obj.skala === true)
      return allObjectsMeetCondition
    },
    hasDimensions () {
      for (const data of this.chart.data) {
        if (data.keys !== undefined) {
          return true
        }
      }
      return false
    },
    chartTypes () {
      var def = this.chartTypesDef

      if (this.chart.timelineAttribute === undefined) {
        def = def.filter(c => c.name !== 'timeline')
      }
      // Can likert and mean
      if (this.chart.config === undefined) {
        def = def.filter(t => t.needsConfig === false || (t.addSkala !== undefined && t.addSkala() === true))
      }
      // Visible
      def = def.filter(c => c.visible === undefined || c.visible() === true)
      return def
    },
    viewTypes () {
      const types = this.viewTypesDef.filter(c => c.types.indexOf(this.chart.type) > -1)
      const types0 = types.filter(c => c.visible === undefined || c.visible() === true)
      return types0
    },
    viewSetting () {
      return this.chart.viewSetting
    },
    addition () {
      return this.chart.addition
    },
    isPreview () {
      return this.$parent.$parent.isPreview
    },
    hasTabs () {
      return this.$parent.$parent.hasTabs === true
    },
    isAdminOrStaff () {
      return this.$parent.$parent.$parent.$parent.$parent.isAdminOrStaff
    }
  },
  methods: {
    download (type) {
      const w = this.$parent.$refs.chart.chart
      const ctx = w.ctx
      switch (type) {
        case 'svg':
          ctx.exports.exportToSVG(ctx)
          break
        case 'png':
          ctx.exports.exportToPng(ctx)
          break
        /* case 'csv':
          ctx.exports.exportToCSV({
            series: this.$parent.series,
            columnDelimiter: ';'
          })
          break */
      }
    },
    changeChart (type) {
      this.isOpen = false
      this.$parent.$emit('on-change-chart', type)
    },
    filterKeys (originalObj, keysToKeep) {
      return keysToKeep.reduce((obj, key) => {
        if (key in originalObj) {
          obj[key] = originalObj[key]
        }
        return obj
      }, {})
    },
    async updateChartViews (name) {
      this.isOpen = false
      this.$parent.$emit('on-update-chart-views', name)
    },
    deleteChart () {
      this.isOpen = false
      this.$parent.$parent.$emit('on-delete-chart', this.chart)
    },
    cloneChart () {
      this.isOpen = false
      this.$parent.$parent.$emit('on-clone-chart', this.chart)
    },
    editChart () {
      this.isOpen = false
      this.$parent.$parent.$emit('on-edit-chart', this.chart)
    },
    moveChart () {
      this.isOpen = false
      this.$parent.$parent.$emit('on-move-chart', this.chart)
    },
    close () {
      this.isOpen = false
    }
  },
  mounted () {
  }
}
</script>
