import Crud from '../../crud'
import { i18n } from '@/main.js'

import ApiClient from '../../../assets/js/ApiClient.js'

let crud = new Crud('accounts/users')

export default {
	namespaced: true,
	state: () => ({
		...crud.state
	}),
	getters: {
		...crud.getters,
		measureAdmins: (rootGetters) => console.log('root', rootGetters),
		hasHistory: () => true,
		contentType: (state, getters, rootState, rootGetters) => rootGetters['accounts/contenttypes/getByName']('accounts.user').id,
		app_permissions_count: (state) => {
			return state.app_permissions ? state.app_permissions.length : 0
		},
		exportActions () {
			return [
				{
					name: 'Benutzerliste',
					path: 'accounts/users/export/'
				},
				{
					name: 'Berechtigungsliste',
					path: 'accounts/app_permissions/export/',
					relationship_id_field: 'user_ids'
				}
			]
		},
		filters () {
			return [
				{
					name: 'Rollen',
					options: [{ id: 1, value: 'Administrator' }, { id: 2, value: 'Benutzer' }],
					field: 'roles'
				}
			]
		},
		title: (state, getters, rootState, rootGetters) => (id) => {
			if (rootGetters.user.id === parseInt(id)) {
				return i18n.t('your-profile')
			} else {
				const item = getters.detailItem
				if (item) {
					return `${item.first_name} ${item.last_name} - ${item.email}`
				} else {
					i18n.t('accounts.users')
				}
			}
		},
		canDelete: (state, getters, rootState, rootGetters) => (id) => rootGetters.user.id !== parseInt(id),
		customAdd: () => 'invitations/+',
		canAddMailJob: () => true,
		salutations: () => [
			{ id: 1, value: i18n.t('male') },
			{ id: 2, value: i18n.t('femail') },
			{ id: 3, value: i18n.t('non-binary') },
			{ id: 4, value: i18n.t('institution') }
		],
		languages: () => [
			{ id: 1, value: i18n.t('german') },
			{ id: 2, value: i18n.t('french') },
			{ id: 3, value: i18n.t('italian') }
		],
		themes: () => [
			{ id: 1, value: 'Light' },
			{ id: 2, value: 'Dark' },
			{ id: 3, value: 'System' }
		],
		fields (state, getters, rootState, rootGetters) {
			const user = rootGetters.user
			const isStaff = user.is_staff
			const isSuperuser = user.is_superuser
			console.log('rootgeter', user, 'and', getters)
			const inMailJobUrl = rootState.fullPathUrl
			const titleOptions = rootGetters['accounts/usertitles/all'].map(title => {
				return {
					id: title.id,
					value: title.title_de
				}
			})

			return [
				{
					text: 'Admin',
					value: 'isAdmin',
					format: 'BooleanView',
					type: 'boolean',
					width: 70,
					hideLists: !(inMailJobUrl === '/mailer/mailjobs/+'),
				},
				{
					text: i18n.t('salutation'),
					value: 'salutation',
					type: 'select',
					width: 80,
					options: getters.salutations
				},
				{
					text: i18n.t('title'),
					value: 'title',
					order: 'title_id',
					type: 'select',
					width: 30,
					options: titleOptions
				},
				{ text: i18n.t('email'), value: 'email', width: -1, readOnlyFunc: (v) => { return isStaff ? !isStaff : v.id !== user.id }, showSelect: true },
				{ text: i18n.t('first_name'), value: 'first_name', width: -1, readOnlyFunc: (v) => { return isStaff ? !isStaff : v.id !== user.id }, showSelect: true },
				{ text: i18n.t('last_name'), value: 'last_name', width: -1, readOnlyFunc: (v) => { return isStaff ? !isStaff : v.id !== user.id }, showSelect: true },
				{ text: i18n.t('mobile'), value: 'authy_phone', width: -1, help: i18n.t('mobile-hint'), hideList: true, hideEdit: !isStaff },
				{
					text: i18n.t('language'),
					value: 'language',
					type: 'select',
					width: -1,
					readOnlyFunc: (v) => { return isStaff ? !isStaff : v.id !== user.id },
					options: getters.languages
				},
				{
					text: 'Theme',
					value: 'theme',
					type: 'select',
					width: -1,
					readOnlyFunc: (v) => { return isStaff ? !isStaff : v.id !== user.id },
					options: getters.themes
				},
				{ text: i18n.t('staff'), value: 'is_staff', format: 'BooleanView', type: 'boolean', width: -1, hideList: !isStaff, hideEdit: !isSuperuser },
				{ text: i18n.t('Demo'), value: 'is_demo', format: 'BooleanView', type: 'boolean', width: -1, hideList: true, hideEdit: !isSuperuser },
				{
					text: i18n.t('permissions'),
					value: 'app_permissions',
					type: 'number',
					width: -1,
					hideList: false,
					hideEdit: true,
					format: (val) => {
						return parseInt(val.toString().replace(/\D/g, ''), 10)
					}
				},
				{ text: i18n.t('permissions'), value: 'app_permissions', order: 'app_permissions_count', type: 'component', width: -1, filterType: 'select', componentName: 'AppPermissions', hideList: true },
				{ text: 'Dateien', value: 'files', type: 'component', componentName: 'Files', hideCreate: !isStaff, hideList: !isStaff, hideEdit: !isStaff, width: -1 }
			]
		}
	},
	mutations: {
		...crud.mutations
	},
	actions: {
		...crud.actions,
		async beforeUpdate ({ dispatch }, user) {
			// check if already an array of numbers
			if (user.app_permissions.length && typeof user.app_permissions[0] === 'number') {
				return user
			}
			let objects = []
			const permissions = await dispatch('accounts/appPermissions/load', { callback: true, all: true, query: { user: user.id } }, { root: true })
			const appPermissions = [].concat.apply([], Object.values(user.app_permissions))
			for (const appPermission of appPermissions) {
				const permission = permissions.find(p => p.object_id === appPermission.object_id && p.content_type === appPermission.content_type)
				if (permission === undefined) {
					// Add Permission
					appPermission.user = user.id
					const newPermission = await dispatch('accounts/appPermissions/add', appPermission, { root: true })
					objects.push(newPermission.id)
				} else {
					if (permission.role !== appPermission.role) {
						// Update Permission
						permission.role = appPermission.role
						await dispatch('accounts/appPermissions/update', permission, { root: true })
					}
					objects.push(permission.id)
				}
			}
			for (const permission of permissions) {
				if (appPermissions.find(p => p.object_id === permission.object_id && p.content_type === permission.content_type) === undefined) {
					// Delete Permission
					await dispatch('accounts/appPermissions/delete', permission, { root: true })
				}
			}
			user.app_permissions = objects
			return user
		},
		afterUpdate ({ commit, rootGetters }, user) {
			if (user.id === rootGetters.user.id) {
				commit('setUser', user, { root: true })
			}
		},
		async loadAdmins ({ commit, state, dispatch, rootGetters }, payload) {
			if (!payload || !payload.ignorePreloads) {
				if (this.getters[`${state.endpoint}/neededCollections`] !== undefined) {
					for (const collection of this.getters[`${state.endpoint}/neededCollections`]) {
						await dispatch(collection + '/load', { ignorePreloads: true, all: true }, { root: true })
					}
				}
			}

			let prom = new Promise((resolve) => {
				dispatch('startLoading', null, { root: true })

				let query = this.getters[`${state.endpoint}/queryGet`] !== undefined
					? JSON.parse(JSON.stringify(this.getters[`${state.endpoint}/queryGet`]))
					: {}
				if (payload && payload.query && payload.query.size === undefined && payload.all === undefined) {
					payload.query.size = 15
				}
				if (payload && payload.all && payload.query && payload.query.size) {
					delete payload.query.size
				}
				if (payload && payload.query && rootGetters.sortKey) {
					payload.query.ordering = rootGetters.sortOrder > 0 ? rootGetters.sortKey : `-${rootGetters.sortKey}`
				}
				if (payload && payload.query && Object.keys(payload.query).length) {
					query = Object.assign(query, payload.query)
				}
				const queryStr = '?' + Object.keys(query).map(key => key + '=' + query[key]).join('&')
				ApiClient.get(state.endpoint + '/' + queryStr).then(response => {
					if (response.data.results) {
						if (payload && payload.callback) {
							if (payload.pagination) {
								resolve(response.data)
							} else {
								resolve(response.data.results)
							}
						} else {
							commit('storeAdminUsers', response.data.results)
						}
					} else {
						if (payload && payload.callback) {
							resolve(response.data)
						} else if (!payload || payload.expand === undefined) {
							commit('store', response.data)
						} else {
							commit('storeOrUpdate', response.data)
						}
					}
				}).catch((err) => {
					Object.entries(err.response.data).forEach(([value]) => {
						const msg = Array.isArray(value) ? value[0] : value
						dispatch('addNotification', { title: 'Fehler', message: msg, error: true, permanent: false }, { root: true })
					})
					dispatch('endLoading', null, { root: true })
				}).finally(() => {
					dispatch('endLoading', null, { root: true })
					resolve()
				})
			})
			return prom
		}
	}
}
